import { EDetailIdType, EProfileFormat } from 'enums/Detail';
import { EErrorType } from 'enums/Error';
import {
  ECompositeState,
  ELossMethodEntryType,
  ERequestStatus,
  ERequestType,
  ETransactionType,
} from 'enums/ETag';
import { ERetreiveState, EUpdateState } from 'enums/General';
import { EPageMode } from 'enums/Page';
import { EViewMode } from 'enums/View';
import {
  IDetailRequest,
  IDistributedTagItemDetailStateLoadTransform,
  IDistributedTagItemDetailStateTransform,
  IFailedDistributedTagItem,
} from 'interfaces/Detail';
import { IEntityInfo, IRegistryEntity } from 'interfaces/Entity';
import {
  IETagConfig,
  IETagMarketInfo,
  IETagMiscInfos,
  IETagPhysicalSegmentsProfile,
  IETagTagId,
  IETagTemplateMessageMetaData,
  IETagTransmissionAllocation,
  IETagTransmissionPhysicalSegment,
  IEtagTransmissionPriorityConfiguration,
} from 'interfaces/ETag';
import {
  IContactInfo,
  IContract,
  IEnergyProductInfo,
  IPseContactInfo,
} from 'interfaces/General';
import { IPointInfo, IRegistryPoint } from 'interfaces/Point';
import { IToEntity } from 'interfaces/ToEntity';
import {
  TDetailRetrievingDistributedTagItems,
  TDetailValidations,
  TProfileDataGridRow,
} from 'types/Detail';
import { TErrorMessage, TErrorType } from 'types/Error';
import {
  TETagDraftId,
  TETagTagPrimaryKey,
  TETagTemplateId,
  TETagEditedPhysicalSegmentColumn,
} from 'types/ETag';
import { TStateTransform } from 'types/General';

export enum EDetailAction {
  SetETagDetailInitialParameters = 'SET_ETAG_DETAIL_INITIAL_PARAMETERS',
  SetETagDetailViewMode = 'SET_ETAG_DETAIL_VIEW_MODE',
  RetrieveETagDetailStart = 'RETRIEVE_ETAG_DETAIL_START',
  RetrieveETagDetailSuccess = 'RETRIEVE_ETAG_DETAIL_SUCCESS',
  RetrieveETagDetailFailure = 'RETRIEVE_ETAG_DETAIL_FAILURE',
  RetrieveETagDistributedTagItemsStart = 'RETRIEVE_ETAG_DISTRIBUTED_TAG_ITEMS_START',
  RetrieveETagDistributedTagItemsSuccess = 'RETRIEVE_ETAG_DISTRIBUTED_TAG_ITEMS_SUCCESS',
  RetrieveETagDistributedTagItemsFailure = 'RETRIEVE_ETAG_DISTRIBUTED_TAG_ITEMS_FAILURE',
  RetrieveETagProfilesStart = 'RETRIEVE_ETAG_PROFILES_START',
  RetrieveETagProfilesSuccess = 'RETRIEVE_ETAG_PROFILES_SUCCESS',
  RetrieveETagProfilesFailure = 'RETRIEVE_ETAG_PROFILES_FAILURE',
  RetrieveETagSnapshotsStart = 'RETRIEVE_ETAG_SNAPSHOTS_START',
  RetrieveETagSnapshotsSuccess = 'RETRIEVE_ETAG_SNAPSHOTS_SUCCESS',
  RetrieveETagSnapshotsFailure = 'RETRIEVE_ETAG_SNAPSHOTS_FAILURE',
  RetrieveETagLossPercentagesV2Start = 'RETRIEVE_ETAG_LOSS_PERCENTAGES_V2_START',
  RetrieveETagLossPercentagesV2Success = 'RETRIEVE_ETAG_LOSS_PERCENTAGES_V2_SUCCESS',
  RetrieveETagLossPercentagesV2Failure = 'RETRIEVE_ETAG_LOSS_PERCENTAGES_V2_FAILURE',
  SetETagDetailSelectedPlotTime = 'SET_ETAG_DETAIL_SELECTED_PLOT_TIME',
  EditETagDetail = 'EDIT_ETAG_DETAIL',
  UpdateComputeLossesV2ETagDetailsStart = 'UPDATE_COMPUTE_LOSSES_V2_ETAG_DETAILS_START',
  UpdateComputeLossesV2ETagDetailsSuccess = 'UPDATE_COMPUTE_LOSSES_V2_ETAG_DETAILS_SUCCESS',
  UpdateComputeLossesV2ETagDetailsFailure = 'UPDATE_COMPUTE_LOSSES_V2_ETAG_DETAILS_FAILURE',
  UpdateETagDetailsStart = 'UPDATE_ETAG_DETAILS_START',
  UpdateETagDetailsSuccess = 'UPDATE_ETAG_DETAILS_SUCCESS',
  UpdateETagDetailsFailure = 'UPDATE_ETAG_DETAILS_FAILURE',
  SetETagDetailValidation = 'SET_ETAG_DETAIL_VALIDATION',
  SetETagDetailValidations = 'SET_ETAG_DETAIL_VALIDATIONS',
  SetETagDetailIsDetailValidating = 'SET_ETAG_DETAIL_IS_DETAIL_VALIDATING',
  SetETagDetailIsDetailDeleted = 'SET_ETAG_DETAIL_IS_DETAIL_DELETED',
  SetETagDetailSelectedProfileFormat = 'SET_ETAG_DETAIL_SELECTED_PROFILE_FORMAT',
  SetETagDetailSelectedRequestType = 'SET_ETAG_DETAIL_SELECTED_REQUEST_TYPE',
  SetETagDetailUseUniqueProfiles = 'SET_ETAG_DETAIL_USE_UNIQUE_PROFILES',
  SetETagDetailProductProfilesApplied = 'SET_ETAG_DETAIL_PRODUCT_PROFILES_APPLIED',
  SetEtagDetailIsMarketInfoNeeded = 'SET_ETAG_DETAIL_IS_MARKED_INFO_NEEDED',
  SetETagDetailEditedPhysicalSegmentProfile = 'SET_ETAG_DETAIL_PHYSICAL_SEGMENT_PROFILE',
  SetETagDetailPhysicalSegmentLossPercentagesV2 = 'SET_ETAG_DETAIL_PHYSICAL_SEGMENT_LOSS_PERCENTAGES_V2',
}

export interface IDetailContactInfos {
  contactInfos: IContactInfo[] | null;
}

export interface IDetailMarketSegment
  extends IDetailContactInfos,
    IETagMiscInfos {
  contracts: IContract[] | null;
  energy_product_ref: IEnergyProductInfo | null;
  market_segment_id: number;
  pse: IEntityInfo | null;
  pse_contact_info: IPseContactInfo | null;
}

export interface IDetailPhysicalSegment
  extends IDetailContactInfos,
    IETagMiscInfos {
  contracts: IContract[] | null;
  energy_product_ref: IEnergyProductInfo | null;
  market_segment_id: number | null;
  mo_code: IEntityInfo | null;
  physical_segment_id: number;
  profile_ref: number | null;
  pse: IEntityInfo | null;
}

export interface IDetailGenerationPhysicalSegment
  extends IDetailPhysicalSegment {
  gca: IEntityInfo | null;
  generation_source: IPointInfo | null;
}

export interface IDetailLoadPhysicalSegment extends IDetailPhysicalSegment {
  lca: IEntityInfo | null;
  load_sink: IPointInfo | null;
}

export interface IDetailLossMethod {
  contractNumbers: IContract[] | null;
  loss_method_entry_type: ELossMethodEntryType | null;
  tag_ids: IETagTagId[] | null;
}

export interface IDetailLossAccounting {
  key: string;
  lossMethod: IDetailLossMethod | null;
  physical_segment_ref: number | null;
  request_ref: number | null;
  start: string | null;
  stop: string | null;
}

export interface IETagDetailPhysicalSegmentLossPercentages {
  physical_segment_loss_percentages: IDetailLossPercentage[] | null;
}

export interface IDetailLossPercentage {
  physical_segment_id: number;
  loss_percentage: number;
}

export interface IDetailTransactionStatus {
  approval_status: string | null;
  approval_status_type: string | null;
  approval_timestamp: string | null;
  approver_notes: string | null;
  delivery_status: string | null;
  entity_code: string | null;
  entity_type: string | null;
}

export interface IDetailTransactionStatuses extends IDetailRequest {
  act_on_by_time: string | null;
  contact_info: IContactInfo | null;
  notes: string | null;
  resolution_status: ERequestStatus | null;
  request_timestamp: string | null;
  statuses: IDetailTransactionStatus[] | null;
  approval_rights?: boolean | null;
}

export interface IDetailEnergyProfileSnapshot {
  last_request_type: ERequestType;
  mw: number | null;
  request_id: number;
}

export interface IDetailEnergyProfileSnapshotInterval {
  snapshots: IDetailEnergyProfileSnapshot[];
  start: string | null;
  stop: string | null;
}

export interface IDetailEnergyProfileSnapshots {
  max_request_id: number;
  min_request_id: number;
  snapshotIntervals: IDetailEnergyProfileSnapshotInterval[];
}

export interface IDetailState {
  active: boolean | null;
  allTransmissionAllocations: IETagTransmissionAllocation[] | null;
  allUnfilteredTransmissionAllocations: IETagTransmissionAllocation[] | null;
  approval_right: boolean | null;
  approved_termination_time: string | null;
  cc_list: IEntityInfo[] | null;
  composite_state: ECompositeState | null;
  computingLossesV2: EUpdateState;
  config: IETagConfig | undefined;
  contact_info: IContactInfo | null;
  creator: string | null;
  description: string | null;
  draft_id: TETagDraftId;
  draftIsResolved: boolean | undefined;
  editedPhysicalSegmentsProfileType: TETagEditedPhysicalSegmentColumn;
  end_date: string | null;
  energyProfileSnapshots: IDetailEnergyProfileSnapshots | null;
  errorMessage: TErrorMessage;
  errorType: TErrorType;
  focusKey: string | null;
  generationPhysicalSegment: IDetailGenerationPhysicalSegment | null;
  group_name: string | null;
  isDetailDeleted: boolean;
  isDetailEdited: boolean;
  isDetailValidating: boolean;
  isMarketInfosEdited: boolean;
  isMarketInfoNeeded: boolean;
  last_update_time: string | null;
  last_update_user: string | null;
  latestErroredDetailIdType: EDetailIdType | null;
  loadPhysicalSegment: IDetailLoadPhysicalSegment | null;
  lossAccountings: IDetailLossAccounting[];
  marketInfos: IETagMarketInfo[];
  marketSegments: IDetailMarketSegment[] | null;
  message_meta_data: IETagTemplateMessageMetaData | null;
  name: string | null;
  notes: string | null;
  pageMode: EPageMode;
  physicalSegmentsProfiles: IETagPhysicalSegmentsProfile[] | null;
  physical_segment_loss_percentages: Record<
    number,
    string | number | null | undefined
  > | null;
  physical_segment_loss_percentages_v2:
    | IDetailLossPercentage[]
    | null
    | undefined;
  retrievingLossPercentages: ERetreiveState;
  productProfilesApplied: string[] | null;
  profileChanges: TProfileDataGridRow[];
  registryEntities: IRegistryEntity[];
  registryPoints: IRegistryPoint[];
  retrievingDetail: ERetreiveState;
  retrievingDistributedTagItems: TDetailRetrievingDistributedTagItems;
  retrievingProfiles: ERetreiveState;
  retrievingSnapshots: ERetreiveState;
  security_key: string | null;
  selectedRequestKey: string;
  selectedPlotTime: Date | undefined;
  selectedProfileFormat: EProfileFormat | undefined;
  selectedRequestType: ERequestType | undefined;
  show_losses: boolean | null;
  losses_v2_enabled: boolean | null;
  start_date: string | null;
  tag_id: IETagTagId | null;
  tag_primary_key: TETagTagPrimaryKey | undefined;
  template_id: TETagTemplateId | undefined;
  test_flag: boolean | null;
  toEntity: IToEntity | null;
  transaction_type: ETransactionType | null;
  transactionStatuses: IDetailTransactionStatuses[];
  transmission_physical_segments: IETagTransmissionPhysicalSegment[] | null;
  transmissionAllocations: IETagTransmissionAllocation[] | null;
  transmissionPriorityConfigurations: IEtagTransmissionPriorityConfiguration[];
  updatingDetail: EUpdateState;
  useUniqueProfiles: boolean;
  validations: TDetailValidations;
  viewMode: EViewMode;
  isPrintView: boolean;
}

export interface IDetailActionPayload {
  ignore?: boolean;
}

export interface IETagDetailInitialParameters extends IDetailActionPayload {
  config?: IETagConfig;
  draft_id: TETagDraftId;
  pageMode: EPageMode;
  tag_primary_key?: TETagTagPrimaryKey;
  template_id?: TETagTemplateId;
  toEntity: IToEntity;
  viewMode: EViewMode;
  selectedProfileFormat?: EProfileFormat;
  isPrintView?: boolean;
}

interface ISetETagDetailInitialParameters {
  payload: IETagDetailInitialParameters;
  type: EDetailAction.SetETagDetailInitialParameters;
}

export interface IETagDetailViewMode extends IDetailActionPayload {
  viewMode: EViewMode;
}

interface ISetETagDetailViewMode {
  payload: IETagDetailViewMode;
  type: EDetailAction.SetETagDetailViewMode;
}

export interface IETagDetailRequest extends IDetailActionPayload {}

interface IRetrieveETagDetailStartAction {
  payload: IETagDetailRequest;
  type: EDetailAction.RetrieveETagDetailStart;
}

export interface IETagDetailSuccessReply extends IDetailActionPayload {
  stateTransforms: TStateTransform<IDetailState>[];
}

interface IRetrieveETagDetailSuccessAction {
  payload: IETagDetailSuccessReply;
  type: EDetailAction.RetrieveETagDetailSuccess;
}

export interface IETagDetailFailureReply extends IDetailActionPayload {
  detailIdType: EDetailIdType;
  errorMessage: string;
  isNotFound: boolean;
}

interface IRetrieveETagDetailFailureAction {
  payload: IETagDetailFailureReply;
  type: EDetailAction.RetrieveETagDetailFailure;
}

export interface IETagDistributedTagItemsRequest extends IDetailActionPayload {
  distributedTagItemDetailStateLoadTransforms: IDistributedTagItemDetailStateLoadTransform[];
}

interface IRetrieveETagDistributedTagItemsStartAction {
  payload: IETagDistributedTagItemsRequest;
  type: EDetailAction.RetrieveETagDistributedTagItemsStart;
}

export interface IETagDistributedTagItemsSuccessReply
  extends IDetailActionPayload {
  distributedTagItemDetailStateTransforms: IDistributedTagItemDetailStateTransform[];
}

interface IRetrieveETagDistributedTagItemsSuccessAction {
  payload: IETagDistributedTagItemsSuccessReply;
  type: EDetailAction.RetrieveETagDistributedTagItemsSuccess;
}

export interface IETagDistributedTagItemsFailureReply
  extends IDetailActionPayload {
  failedDistributedTagItems: IFailedDistributedTagItem[];
}

interface IRetrieveETagDistributedTagItemsFailureAction {
  payload: IETagDistributedTagItemsFailureReply;
  type: EDetailAction.RetrieveETagDistributedTagItemsFailure;
}

export interface IETagSnapshotsRequest extends IDetailActionPayload {}

interface IRetrieveETagSnapshotsStartAction {
  payload: IETagSnapshotsRequest;
  type: EDetailAction.RetrieveETagSnapshotsStart;
}

export interface IETagSnapshotsSuccessReply extends IDetailActionPayload {
  stateTransforms: TStateTransform<IDetailState>[];
}

interface IRetrieveETagSnapshotsSuccessAction {
  payload: IETagSnapshotsSuccessReply;
  type: EDetailAction.RetrieveETagSnapshotsSuccess;
}

export interface IETagSnapshotsFailureReply extends IDetailActionPayload {
  errorMessage: string;
}

interface IRetrieveETagSnapshotsFailureAction {
  payload: IETagSnapshotsFailureReply;
  type: EDetailAction.RetrieveETagSnapshotsFailure;
}

export interface IETagLossPercentagesV2Request extends IDetailActionPayload {}

interface IRetrieveETagLossPercentagesV2StartAction {
  payload: IETagLossPercentagesV2Request;
  type: EDetailAction.RetrieveETagLossPercentagesV2Start;
}

export interface IETagLossPercentagesV2SuccessReply
  extends IDetailActionPayload {
  stateTransforms: TStateTransform<IDetailState>[];
}

interface IRetrieveETagLossPercentagesV2SuccessAction {
  payload: IETagLossPercentagesV2SuccessReply;
  type: EDetailAction.RetrieveETagLossPercentagesV2Success;
}

export interface IETagLossPercentagesV2FailureReply
  extends IDetailActionPayload {
  errorMessage: string;
  isNotFound: boolean;
}

interface IRetrieveETagLossPercentagesV2FailureAction {
  payload: IETagLossPercentagesV2FailureReply;
  type: EDetailAction.RetrieveETagLossPercentagesV2Failure;
}

interface IRetrieveETagLossPercentagesV2FailureAction {
  payload: IETagLossPercentagesV2FailureReply;
  type: EDetailAction.RetrieveETagLossPercentagesV2Failure;
}

export interface IETagProfilesRequest extends IDetailActionPayload {
  selectedRequestKey: string;
}

interface IRetrieveETagProfilesStartAction {
  payload: IETagProfilesRequest;
  type: EDetailAction.RetrieveETagProfilesStart;
}

export interface IETagProfilesSuccessReply extends IDetailActionPayload {
  stateTransforms: TStateTransform<IDetailState>[];
}

interface IRetrieveETagProfilesSuccessAction {
  payload: IETagProfilesSuccessReply;
  type: EDetailAction.RetrieveETagProfilesSuccess;
}

export interface IETagProfilesFailureReply extends IDetailActionPayload {
  errorMessage: string;
}

interface IRetrieveETagProfilesFailureAction {
  payload: IETagProfilesFailureReply;
  type: EDetailAction.RetrieveETagProfilesFailure;
}

export interface IETagDetailSelectedPlotTime extends IDetailActionPayload {
  selectedPlotTime: Date | undefined;
}

interface ISetETagDetailSelectedPlotTime {
  payload: IETagDetailSelectedPlotTime;
  type: EDetailAction.SetETagDetailSelectedPlotTime;
}

export interface IETagDetail extends IDetailActionPayload {
  isDetailEdited?: boolean;
  isMarketInfosEdited?: boolean;
  stateTransform?: TStateTransform<IDetailState>;
}

interface IEditETagDetail {
  payload: IETagDetail;
  type: EDetailAction.EditETagDetail;
}

export interface IETagDetailsUpdateRequest extends IDetailActionPayload {}

interface IUpdateETagDetailsStartAction {
  payload: IETagDetailsUpdateRequest;
  type: EDetailAction.UpdateETagDetailsStart;
}

export interface IETagDetailsUpdateSuccessReply extends IDetailActionPayload {}

interface IUpdateETagDetailsSuccessAction {
  payload: IETagDetailsUpdateSuccessReply;
  type: EDetailAction.UpdateETagDetailsSuccess;
}

export interface IETagDetailsUpdateFailureReply extends IDetailActionPayload {
  errorMessage: string;
  errorType: EErrorType;
}

interface IUpdateETagDetailsFailureAction {
  payload: IETagDetailsUpdateFailureReply;
  type: EDetailAction.UpdateETagDetailsFailure;
}

export interface IETagDetailsComputeLossesV2Request
  extends IDetailActionPayload {}

interface IComputeLossesV2ETagDetailsStartAction {
  payload: IETagDetailsComputeLossesV2Request;
  type: EDetailAction.UpdateComputeLossesV2ETagDetailsStart;
}

export interface IETagDetailsComputeLossesV2SuccessReply
  extends IDetailActionPayload {}

interface IComputeLossesV2ETagDetailsSuccessAction {
  payload: IETagDetailsComputeLossesV2SuccessReply;
  type: EDetailAction.UpdateComputeLossesV2ETagDetailsSuccess;
}

export interface IETagDetailsComputeLossesV2FailureReply
  extends IDetailActionPayload {
  errorMessage: string;
  errorType: EErrorType;
}

interface IComputeLossesV2ETagDetailsFailureAction {
  payload: IETagDetailsComputeLossesV2FailureReply;
  type: EDetailAction.UpdateComputeLossesV2ETagDetailsFailure;
}

export interface IETagDetailValidation extends IDetailActionPayload {
  id: string;
  isValid: boolean;
}

interface ISetETagDetailValidation {
  payload: IETagDetailValidation;
  type: EDetailAction.SetETagDetailValidation;
}

export interface IETagDetailValidations extends IDetailActionPayload {
  detailValidations: TDetailValidations;
}

interface ISetETagDetailValidations {
  payload: IETagDetailValidations;
  type: EDetailAction.SetETagDetailValidations;
}

export interface IETagDetailIsDetailValidating extends IDetailActionPayload {
  isDetailValidating: boolean;
}

interface ISetETagDetailIsValidating {
  payload: IETagDetailIsDetailValidating;
  type: EDetailAction.SetETagDetailIsDetailValidating;
}

export interface IETagDetailIsDetailDeleted extends IDetailActionPayload {
  isDetailDeleted: boolean;
}

interface ISetETagDetailIsDeleted {
  payload: IETagDetailIsDetailDeleted;
  type: EDetailAction.SetETagDetailIsDetailDeleted;
}

export interface IETagDetailSelectedProfileFormat extends IDetailActionPayload {
  selectedProfileFormat: EProfileFormat | undefined;
}

interface ISetETagDetailSelectedProfileFormat {
  payload: IETagDetailSelectedProfileFormat;
  type: EDetailAction.SetETagDetailSelectedProfileFormat;
}

export interface IETagDetailSelectedRequestType extends IDetailActionPayload {
  selectedRequestType: ERequestType | undefined;
}

interface ISetETagDetailSelectedRequestType {
  payload: IETagDetailSelectedRequestType;
  type: EDetailAction.SetETagDetailSelectedRequestType;
}

export interface IETagDetailUseUniqueProfiles extends IDetailActionPayload {
  useUniqueProfiles: boolean;
}

interface ISetETagDetailUseUniqueProfiles {
  payload: IETagDetailUseUniqueProfiles;
  type: EDetailAction.SetETagDetailUseUniqueProfiles;
}

export interface IETagDetailProductProfilesApplied
  extends IDetailActionPayload {
  productProfilesApplied: string[];
}

interface ISetETagDetailProductProfilesApplied {
  payload: IETagDetailProductProfilesApplied;
  type: EDetailAction.SetETagDetailProductProfilesApplied;
}

export interface IEtagDetailIsMarketInfoNeeded extends IDetailActionPayload {
  isMarketInfoNeeded: boolean;
}

interface ISetEtagDetailIsMarketInfoNeeded {
  payload: IEtagDetailIsMarketInfoNeeded;
  type: EDetailAction.SetEtagDetailIsMarketInfoNeeded;
}

export interface IEtagDetailEditedPhysicalSegmentProfile
  extends IDetailActionPayload {
  editedPhysicalSegmentsProfileType: TETagEditedPhysicalSegmentColumn;
}

interface ISetEtagDetailEditedPhysicalSegmentProfile {
  payload: IEtagDetailEditedPhysicalSegmentProfile;
  type: EDetailAction.SetETagDetailEditedPhysicalSegmentProfile;
}

export interface IETagDetailPhysicalSegmentLossPercentagesV2
  extends IDetailActionPayload {
  physical_segment_loss_percentages: IDetailLossPercentage[] | null;
}

interface ISetETagDetailPhysicalSegmentLossPercentagesV2 {
  payload: IETagDetailPhysicalSegmentLossPercentagesV2;
  type: EDetailAction.SetETagDetailPhysicalSegmentLossPercentagesV2;
}

// Actions

export type TDetailAction =
  | ISetETagDetailInitialParameters
  | ISetETagDetailViewMode
  | IRetrieveETagDetailStartAction
  | IRetrieveETagDetailSuccessAction
  | IRetrieveETagDetailFailureAction
  | IRetrieveETagDistributedTagItemsStartAction
  | IRetrieveETagDistributedTagItemsSuccessAction
  | IRetrieveETagDistributedTagItemsFailureAction
  | IRetrieveETagProfilesStartAction
  | IRetrieveETagProfilesSuccessAction
  | IRetrieveETagProfilesFailureAction
  | IRetrieveETagSnapshotsStartAction
  | IRetrieveETagSnapshotsSuccessAction
  | IRetrieveETagSnapshotsFailureAction
  | IRetrieveETagLossPercentagesV2StartAction
  | IRetrieveETagLossPercentagesV2SuccessAction
  | IRetrieveETagLossPercentagesV2FailureAction
  | ISetETagDetailSelectedPlotTime
  | IUpdateETagDetailsStartAction
  | IUpdateETagDetailsSuccessAction
  | IUpdateETagDetailsFailureAction
  | IComputeLossesV2ETagDetailsStartAction
  | IComputeLossesV2ETagDetailsSuccessAction
  | IComputeLossesV2ETagDetailsFailureAction
  | IEditETagDetail
  | ISetETagDetailValidation
  | ISetETagDetailValidations
  | ISetETagDetailIsValidating
  | ISetETagDetailIsDeleted
  | ISetETagDetailSelectedProfileFormat
  | ISetETagDetailSelectedRequestType
  | ISetETagDetailUseUniqueProfiles
  | ISetETagDetailProductProfilesApplied
  | ISetEtagDetailIsMarketInfoNeeded
  | ISetEtagDetailEditedPhysicalSegmentProfile
  | ISetETagDetailPhysicalSegmentLossPercentagesV2;
