import Checkbox from 'components/atoms/Checkbox/Checkbox';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { hasMultipleEnergyProfiles } from 'components/organisms/ProfileInformationView/SameEnergyProfilesCheckbox/helpers';
import { EViewMode } from 'enums/View';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { detailSetUseUniqueProfiles } from 'reduxes/Detail/actions';
import { TRootState } from 'types/Redux';

const retrieveSameEnergyProfilesCheckboxState = (state: TRootState) => {
  const { physicalSegmentsProfiles, useUniqueProfiles, viewMode } =
    state.detail.present;
  const losses_v2 = state.detail.present.config?.losses_v2_enabled || null;

  return {
    physicalSegmentsProfiles,
    useUniqueProfiles,
    viewMode,
    losses_v2,
  };
};

interface IProps {
  isDisabled?: boolean;
  lossesV2?: boolean;
}

const SameEnergyProfilesCheckbox = (props: IProps): JSX.Element => {
  const dispatch = useDispatch();

  const { physicalSegmentsProfiles, useUniqueProfiles, viewMode } = useSelector(
    retrieveSameEnergyProfilesCheckboxState,
  );
  const { isDisabled, lossesV2 } = props;

  const [lossesV2Read, setLossesV2Read] = useState(false);

  useEffect(() => {
    if (!lossesV2Read && lossesV2) {
      dispatch(
        detailSetUseUniqueProfiles({
          useUniqueProfiles: true,
        }),
      );
      setLossesV2Read(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lossesV2]);

  const allowMultipleEnergyProfiles: boolean = useMemo(() => {
    if (
      viewMode === EViewMode.EditETagAdjustment ||
      viewMode === EViewMode.EditETagAdjustmentWithATF
    ) {
      return hasMultipleEnergyProfiles(physicalSegmentsProfiles);
    }

    return true;
  }, [physicalSegmentsProfiles, viewMode]);

  const handleUseUniqueProfilesChange = useCallback(
    (useUniqueProfiles: boolean) => {
      dispatch(
        detailSetUseUniqueProfiles({
          useUniqueProfiles: !useUniqueProfiles,
        }),
      );
    },
    [dispatch],
  );

  const adjustedIsDisabled: boolean =
    isDisabled || !allowMultipleEnergyProfiles;

  const [checked, setChecked] = useState(lossesV2 ? false : !useUniqueProfiles);

  useEffect(() => {
    setChecked(!useUniqueProfiles);
  }, [useUniqueProfiles]);

  return (
    <Tooltip
      isDisabled={adjustedIsDisabled}
      title={
        checked
          ? 'Uncheck to Allow Entering Different Energy Profiles for Each Physical Path to Account for Transmission Physical Loss'
          : 'Check to Have All Physical Paths Referring to the Same Energy Profile Id'
      }
    >
      <Checkbox
        checked={checked}
        isDisabled={adjustedIsDisabled}
        label='Same Energy Profiles'
        onChange={handleUseUniqueProfilesChange}
      />
    </Tooltip>
  );
};

export default SameEnergyProfilesCheckbox;
