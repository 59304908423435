import EditableDataTable, {
  IEditableDataTableProps,
} from 'components/molecules/EditableDataTable/EditableDataTable';
import { PHYSICAL_PATH_VIEW_EDIT_BASE_WIDTH_VALUE } from 'components/organisms/PhysicalPathView/constants';
import {
  BUTTON_ICON_DIMENSION_VALUE,
  COLUMN_LAYOUT_SHARED_STYLES,
} from 'constants/styles';
import { ERegistryType, EUpdateState } from 'enums/General';
import { EViewMode } from 'enums/View';
import useGenerationEditColumns from 'hooks/useGenerationEditColumns';
import useLoadEditColumns from 'hooks/useLoadEditColumns';
import usePrevious from 'hooks/usePrevious';
import useToEntityId from 'hooks/useToEntityId';
import useTransmissionEditColumns from 'hooks/useTransmissionEditColumns/useTransmissionEditColumns';
import { IOption } from 'interfaces/Component';
import { IEditPhysicalPathInformation } from 'interfaces/Detail';
import { IEntityInfo, IRegistryEntity } from 'interfaces/Entity';
import { IETagTransmissionPhysicalSegment } from 'interfaces/ETag';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { detailSetValidation } from 'reduxes/Detail/actions';
import {
  IDetailGenerationPhysicalSegment,
  IDetailLoadPhysicalSegment,
} from 'reduxes/Detail/types';
import styled from 'styled-components';
import { TRootState } from 'types/Redux';
import { TToEntityId } from 'types/ToEntity';
import {
  getInitialGenerationPhysicalSegment,
  getInitialLoadPhysicalSegment,
  getInitialTransmissionPhysicalSegment,
  getNextLoadPhysicalSegmentId,
} from 'utils/detail';
import { registryEntityToEntityInfoOption } from 'utils/entity';

const ADD_BUTTON_OFFSET = {
  right: '0',
  top: 'calc(100% - 2px)',
};

const REMOVE_BUTTON_OFFSET = {
  right: '0',
  top: `calc(50% - ${BUTTON_ICON_DIMENSION_VALUE / 2}px)`,
};

const entityToTpOption = registryEntityToEntityInfoOption(true, false);
const entityToMoOption = registryEntityToEntityInfoOption(true, false);
const entityToBaOption = registryEntityToEntityInfoOption(true, false);

const Layout = styled.div`
  ${COLUMN_LAYOUT_SHARED_STYLES}
`;

// Specialize the EditableDataTable component
interface IWithViewWidth {
  viewWidth: number;
}

const GenerationPhysicalSegmentDataTable = styled(
  (
    props: IEditableDataTableProps<IDetailGenerationPhysicalSegment> &
      IWithViewWidth,
  ) => EditableDataTable<IDetailGenerationPhysicalSegment>(props),
)`
  ${(props) =>
    props.viewWidth < PHYSICAL_PATH_VIEW_EDIT_BASE_WIDTH_VALUE
      ? `
      table {
        padding-right: 20px;
      }
    `
      : ''}
`;

const TransmissionPhysicalSegmentDataTable = styled(
  (
    props: IEditableDataTableProps<IETagTransmissionPhysicalSegment> &
      IWithViewWidth,
  ) => EditableDataTable<IETagTransmissionPhysicalSegment>(props),
)`
  ${(props) =>
    props.viewWidth < PHYSICAL_PATH_VIEW_EDIT_BASE_WIDTH_VALUE
      ? `
      table {
        padding-right: 20px;
      }
    `
      : ''}
`;

const LoadPhysicalSegmentDataTable = styled(
  (
    props: IEditableDataTableProps<IDetailLoadPhysicalSegment> & IWithViewWidth,
  ) => EditableDataTable<IDetailLoadPhysicalSegment>(props),
)`
  ${(props) =>
    props.viewWidth < PHYSICAL_PATH_VIEW_EDIT_BASE_WIDTH_VALUE
      ? `
      table {
        padding-right: 20px;
      }
    `
      : ''}
`;

interface IPhysicalPathEditProps {
  generationPhysicalSegments: IDetailGenerationPhysicalSegment[];
  initialGenerationPhysicalSegments:
    | IDetailGenerationPhysicalSegment[]
    | undefined;
  initialLoadPhysicalSegments: IDetailLoadPhysicalSegment[] | undefined;
  initialTransmissionPhysicalSegments:
    | IETagTransmissionPhysicalSegment[]
    | undefined;
  isDisabled?: boolean;
  isUnconstrained?: boolean;
  loadPhysicalSegments: IDetailLoadPhysicalSegment[];
  onChange: (editPhysicalPathInformation: IEditPhysicalPathInformation) => void;
  previousIsDetailUpdating: boolean | undefined;
  transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[];
  viewWidth: number;
}

const retrievePhysicalPathEditState = (state: TRootState) => {
  const {
    config,
    composite_state,
    focusKey,
    isDetailDeleted,
    isDetailValidating,
    marketSegments,
    registryEntities,
    tag_id,
    toEntity,
    updatingDetail,
    validations,
    viewMode,
  } = state.detail.present;
  const isDetailUpdating: boolean =
    updatingDetail !== EUpdateState.NotUpdating &&
    updatingDetail !== EUpdateState.UpdateCompleted;

  return {
    config,
    composite_state,
    focusKey,
    isDetailDeleted,
    isDetailUpdating,
    isDetailValidating,
    marketSegments,
    registryEntities,
    tag_id,
    toEntity,
    validations,
    viewMode,
  };
};

const PhysicalPathEdit = ({
  generationPhysicalSegments,
  initialGenerationPhysicalSegments,
  initialLoadPhysicalSegments,
  initialTransmissionPhysicalSegments,
  isDisabled,
  isUnconstrained = false,
  loadPhysicalSegments,
  onChange,
  previousIsDetailUpdating,
  transmissionPhysicalSegments,
  viewWidth,
}: IPhysicalPathEditProps): JSX.Element => {
  const dispatch = useDispatch();
  const {
    config,
    composite_state,
    focusKey,
    isDetailDeleted,
    isDetailUpdating,
    isDetailValidating,
    marketSegments,
    registryEntities,
    tag_id,
    toEntity,
    validations,
    viewMode,
  } = useSelector(retrievePhysicalPathEditState);
  const [midOptions, setMidOptions] = useState<IOption<number>[]>([]);
  const previousIsDetailDeleted: boolean | undefined =
    usePrevious(isDetailDeleted);
  const previousIsDetailValidating: boolean | undefined =
    usePrevious(isDetailValidating);
  const previousValidations: Record<string, boolean> | undefined =
    usePrevious(validations);
  const previousMidOptions: IOption<number>[] | undefined =
    usePrevious(midOptions);

  const toEntityId: TToEntityId = useToEntityId(toEntity);

  const setDetailValidation = useCallback(
    (id: string, isValid: boolean) =>
      dispatch(detailSetValidation({ id, isValid })),
    [dispatch],
  );

  useEffect(() => {
    if (marketSegments === null) {
      if (midOptions.length > 0) {
        setMidOptions([]);
      }
    } else if (midOptions.length !== marketSegments.length) {
      const newMidOptions: IOption<number>[] = [];

      for (let i: number = 1; i <= marketSegments.length; i += 1) {
        newMidOptions.push({ label: `${i}`, value: i });
      }

      setMidOptions(newMidOptions);
    }
  }, [marketSegments, midOptions]);

  const tpOptions: IOption<IEntityInfo>[] = useMemo(
    () =>
      registryEntities
        .filter(
          (registryEntity: IRegistryEntity): boolean =>
            registryEntity['Registry Type'] === ERegistryType.TSP,
        )
        .map(entityToTpOption),
    [registryEntities],
  );

  const previousTpOptions: IOption<IEntityInfo>[] | undefined =
    usePrevious(tpOptions);

  const moOptions: IOption<IEntityInfo>[] = useMemo(
    () =>
      registryEntities
        .filter(
          (registryEntity: IRegistryEntity): boolean =>
            registryEntity['Registry Type'] === ERegistryType.MO,
        )
        .map(entityToMoOption),
    [registryEntities],
  );

  const previousMoOptions: IOption<IEntityInfo>[] | undefined =
    usePrevious(moOptions);

  const baOptions: IOption<IEntityInfo>[] = useMemo(
    () =>
      registryEntities
        .filter(
          (registryEntity: IRegistryEntity): boolean =>
            registryEntity['Registry Type'] === ERegistryType.BA,
        )
        .map(entityToBaOption),
    [registryEntities],
  );

  const previousBaOptions: IOption<IEntityInfo>[] | undefined =
    usePrevious(baOptions);

  const generationColumns = useGenerationEditColumns(
    validations,
    focusKey,
    tag_id === null ? null : tag_id.gca,
    generationPhysicalSegments.length === 1
      ? generationPhysicalSegments[0].pse
      : null,
    initialGenerationPhysicalSegments,
    isDetailDeleted,
    isDetailUpdating,
    isDetailValidating,
    isUnconstrained,
    midOptions,
    moOptions,
    onChange,
    previousIsDetailDeleted,
    previousIsDetailUpdating,
    previousIsDetailValidating,
    previousMidOptions,
    previousMoOptions,
    previousValidations,
    setDetailValidation,
    toEntityId,
    viewMode,
    isDisabled,
  );

  const loadColumns = useLoadEditColumns(
    validations,
    focusKey,
    tag_id === null ? null : tag_id.lca,
    loadPhysicalSegments.length === 1 ? loadPhysicalSegments[0].pse : null,
    initialLoadPhysicalSegments,
    isDetailDeleted,
    isDetailUpdating,
    isDetailValidating,
    isUnconstrained,
    midOptions,
    moOptions,
    onChange,
    previousIsDetailDeleted,
    previousIsDetailUpdating,
    previousIsDetailValidating,
    previousMidOptions,
    previousMoOptions,
    previousValidations,
    setDetailValidation,
    toEntityId,
    viewMode,
  );

  const transmissionColumns = useTransmissionEditColumns(
    baOptions,
    composite_state,
    focusKey,
    initialTransmissionPhysicalSegments,
    isDetailDeleted,
    isDetailUpdating,
    isUnconstrained,
    midOptions,
    moOptions,
    onChange,
    previousBaOptions,
    previousIsDetailDeleted,
    previousIsDetailUpdating,
    previousMidOptions,
    previousMoOptions,
    previousTpOptions,
    toEntityId,
    tpOptions,
    viewMode,
    isDisabled,
    config?.show_losses,
    config?.losses_v2_enabled,
  );

  const generationInitialiser = useCallback(
    () => getInitialGenerationPhysicalSegment(tag_id),
    [tag_id],
  );

  const handleGenerationRemove = useCallback(
    (record: IDetailGenerationPhysicalSegment) => {
      onChange({
        editGenerationPhysicalSegment: {
          removeGenerationPhysicalSegment: true,
        },
      });
    },
    [onChange],
  );

  // This is only called when there are no pre-existing transmission physical
  // segments and thus its physical_segment_id follows directly after the
  // generation physical segment id of 1 i.e. 2.
  const transmissionInitialiser = useCallback(
    () => getInitialTransmissionPhysicalSegment(2),
    [],
  );

  const handleTransmissionAdd = useCallback(
    (value: unknown, record: IETagTransmissionPhysicalSegment) => {
      onChange({
        editTransmissionPhysicalSegments: {
          addAfterPhysicalSegmentId: record.physical_segment_id,
        },
      });
    },
    [onChange],
  );

  const handleTransmissionRemove = useCallback(
    (record: IETagTransmissionPhysicalSegment) => {
      onChange({
        editTransmissionPhysicalSegments: {
          removePhysicalSegmentId: record.physical_segment_id,
        },
      });
    },
    [onChange],
  );

  const loadInitialiser = useCallback(
    () =>
      getInitialLoadPhysicalSegment(
        getNextLoadPhysicalSegmentId(transmissionPhysicalSegments),
        tag_id,
      ),
    [tag_id, transmissionPhysicalSegments],
  );

  const handleLoadRemove = useCallback(
    (record: IDetailLoadPhysicalSegment) => {
      onChange({
        editLoadPhysicalSegment: {
          removeLoadPhysicalSegment: true,
        },
      });
    },
    [onChange],
  );

  const GenerationPhysicalSegment = useMemo(
    () => (
      <GenerationPhysicalSegmentDataTable
        columns={generationColumns}
        data={generationPhysicalSegments}
        initialiser={generationInitialiser}
        isDisabled={isDisabled}
        maximumAllowableAdds={1}
        onRemove={
          viewMode === EViewMode.EditETagCorrection
            ? undefined
            : handleGenerationRemove
        }
        pagination={false}
        removeButtonOffset={REMOVE_BUTTON_OFFSET}
        rowKey='physical_segment_id'
        tableLayout='fixed'
        viewWidth={viewWidth}
      />
    ),
    [
      generationColumns,
      generationInitialiser,
      generationPhysicalSegments,
      handleGenerationRemove,
      isDisabled,
      viewMode,
      viewWidth,
    ],
  );

  const TransmissionPhysicalSegments = useMemo(
    () => (
      <TransmissionPhysicalSegmentDataTable
        addButtonOffset={ADD_BUTTON_OFFSET}
        columns={transmissionColumns}
        data={transmissionPhysicalSegments}
        initialiser={transmissionInitialiser}
        isDisabled={isDisabled}
        maximumAllowableAdds={-1}
        onAdd={
          viewMode === EViewMode.EditETagCorrection
            ? undefined
            : handleTransmissionAdd
        }
        onRemove={
          viewMode === EViewMode.EditETagCorrection
            ? undefined
            : handleTransmissionRemove
        }
        pagination={false}
        removeButtonOffset={REMOVE_BUTTON_OFFSET}
        rowKey='physical_segment_id'
        tableLayout='fixed'
        viewWidth={viewWidth}
      />
    ),
    [
      handleTransmissionAdd,
      handleTransmissionRemove,
      isDisabled,
      transmissionColumns,
      transmissionInitialiser,
      transmissionPhysicalSegments,
      viewMode,
      viewWidth,
    ],
  );

  const LoadPhysicalSegment = useMemo(
    () => (
      <LoadPhysicalSegmentDataTable
        columns={loadColumns}
        data={loadPhysicalSegments}
        initialiser={loadInitialiser}
        isDisabled={isDisabled}
        maximumAllowableAdds={1}
        onRemove={
          viewMode === EViewMode.EditETagCorrection
            ? undefined
            : handleLoadRemove
        }
        pagination={false}
        removeButtonOffset={REMOVE_BUTTON_OFFSET}
        rowKey='physical_segment_id'
        tableLayout='fixed'
        viewWidth={viewWidth}
      />
    ),
    [
      handleLoadRemove,
      isDisabled,
      loadColumns,
      loadInitialiser,
      loadPhysicalSegments,
      viewMode,
      viewWidth,
    ],
  );

  return (
    <Layout>
      {GenerationPhysicalSegment}
      {TransmissionPhysicalSegments}
      {LoadPhysicalSegment}
    </Layout>
  );
};

export default PhysicalPathEdit;
