import { INITIAL_RECORD_ID } from 'constants/Detail';
import {
  IEditOasisInfo,
  IEditTransmissionAllocation,
} from 'hooks/useTransmissionEditColumns/OasisInfoEdit/types';
import { ITransAllocProfile } from 'interfaces/Detail';
import {
  IETagPhysicalSegmentsProfile,
  IETagPhysicalSegmentsProfiles,
  IETagTransmissionAllocation,
  IETagTransmissionAllocationProfile,
  IETagTransmissionPhysicalSegment,
  IETagTransmissionPhysicalSegmentProfile,
  IETagTransmissionSegment,
} from 'interfaces/ETag';
import { IMiscInfo } from 'interfaces/General';
import { IDetailState } from 'reduxes/Detail/types';
import { TDetailValidations, TProfileDataGridRow } from 'types/Detail';
import { TStateTransform } from 'types/General';
import {
  copyTransmissionAllocation,
  getAdjustedContractNumber,
  getEditInfoKey,
  getInitialMiscInfo,
  getInitialTransmissionAllocation,
  getSplitEditInfoKey,
  getTransmissionAllocationKey,
  getTransmissionName,
  isValidTransmissionAllocation,
  transmissionAllocationSorter,
} from 'utils/detail';
import {
  getTransAllocProfileForKey,
  getKeyForTransAllocProfile,
} from 'utils/detail-energy';

interface IUpdatedTransAllocProfiles {
  hasTransAllocId?: boolean;
  hasUpdated: boolean;
  updatedTransAllocProfiles: IETagTransmissionAllocationProfile[];
}

const updateTransAllocProfilesForAddAfter = (
  transAllocId: number,
  transAllocProfiles: IETagTransmissionAllocationProfile[],
): IUpdatedTransAllocProfiles => {
  const updatedTransAllocProfiles: IETagTransmissionAllocationProfile[] = [
    ...transAllocProfiles,
  ];
  let hasUpdated: boolean = false;

  updatedTransAllocProfiles.forEach(
    (
      eTagTransmissionAllocationProfile: IETagTransmissionAllocationProfile,
      transAllocProfilesIndex: number,
    ) => {
      const { trans_alloc_id } = eTagTransmissionAllocationProfile;

      if (trans_alloc_id !== null && trans_alloc_id > transAllocId) {
        updatedTransAllocProfiles[transAllocProfilesIndex] = {
          ...eTagTransmissionAllocationProfile,
          trans_alloc_id: trans_alloc_id + 1,
        };

        hasUpdated = true;
      }
    },
  );

  return {
    hasUpdated,
    updatedTransAllocProfiles,
  };
};

const updateTransAllocProfilesForEdit = (
  transAllocId: number,
  transAllocProfiles: IETagTransmissionAllocationProfile[],
  contract_number?: string | null,
): IUpdatedTransAllocProfiles => {
  const updatedTransAllocProfiles: IETagTransmissionAllocationProfile[] = [
    ...transAllocProfiles,
  ];
  let hasUpdated: boolean = false;

  const updatedTransAllocProfileIndex: number =
    updatedTransAllocProfiles.findIndex(
      (
        eTagTransmissionAllocationProfile: IETagTransmissionAllocationProfile,
      ): boolean =>
        eTagTransmissionAllocationProfile.trans_alloc_id === transAllocId,
    );
  const hasTransAllocId: boolean = updatedTransAllocProfileIndex !== -1;

  if (contract_number !== undefined) {
    if (hasTransAllocId) {
      updatedTransAllocProfiles[updatedTransAllocProfileIndex] = {
        ...updatedTransAllocProfiles[updatedTransAllocProfileIndex],
        contract_number,
      };
    }
  }

  return {
    hasTransAllocId,
    hasUpdated,
    updatedTransAllocProfiles,
  };
};

const updateTransAllocProfilesForRemove = (
  transAllocId: number,
  transAllocProfiles: IETagTransmissionAllocationProfile[],
): IUpdatedTransAllocProfiles => {
  const updatedTransAllocProfiles: IETagTransmissionAllocationProfile[] = [];

  transAllocProfiles.forEach(
    (eTagTransmissionAllocationProfile: IETagTransmissionAllocationProfile) => {
      if (eTagTransmissionAllocationProfile.trans_alloc_id === null) {
        updatedTransAllocProfiles.push(eTagTransmissionAllocationProfile);
      } else if (
        eTagTransmissionAllocationProfile.trans_alloc_id > transAllocId
      ) {
        updatedTransAllocProfiles.push({
          ...eTagTransmissionAllocationProfile,
          trans_alloc_id: eTagTransmissionAllocationProfile.trans_alloc_id - 1,
        });
      } else if (
        eTagTransmissionAllocationProfile.trans_alloc_id !== transAllocId
      ) {
        updatedTransAllocProfiles.push(eTagTransmissionAllocationProfile);
      }
    },
  );

  return {
    hasUpdated: true,
    updatedTransAllocProfiles,
  };
};

const updatePhysicalSegmentsProfilesFor = (
  transAllocId: number,
  physicalSegmentsProfiles: IETagPhysicalSegmentsProfile[] | null,
  updateTransAllocProfilesFor: (
    transAllocId: number,
    transAllocProfiles: IETagTransmissionAllocationProfile[],
    contract_number?: string | null,
  ) => IUpdatedTransAllocProfiles,
  physicalSegmentId?: number | null,
  contract_number?: string | null,
): IETagPhysicalSegmentsProfile[] | null => {
  if (physicalSegmentsProfiles !== null) {
    const updatedPhysicalSegmentsProfiles: IETagPhysicalSegmentsProfile[] = [
      ...physicalSegmentsProfiles,
    ];
    let isUpdated: boolean = false;

    updatedPhysicalSegmentsProfiles.forEach(
      (
        eTagPhysicalSegmentsProfile: IETagPhysicalSegmentsProfile,
        physicalSegmentsProfileIndex: number,
      ) => {
        const updatedPhysicalSegmentsProfile = {
          ...eTagPhysicalSegmentsProfile,
        };
        const { physical_segments_profiles } = updatedPhysicalSegmentsProfile;

        if (physical_segments_profiles !== null) {
          const updatedPhysicalSegmentsProfiles: IETagPhysicalSegmentsProfiles =
            {
              ...physical_segments_profiles,
            };
          const { transmission } = updatedPhysicalSegmentsProfiles;

          if (transmission !== null) {
            const updatedTransmission: IETagTransmissionPhysicalSegmentProfile =
              {
                ...transmission,
              };
            const { transmission_segments } = updatedTransmission;

            if (transmission_segments !== null) {
              const updatedTransmissionSegments: IETagTransmissionSegment[] = [
                ...transmission_segments,
              ];

              updatedTransmissionSegments.forEach(
                (
                  eTagTransmissionSegment: IETagTransmissionSegment,
                  transmissionSegmentsIndex: number,
                ) => {
                  const updatedTransmissionSegment: IETagTransmissionSegment = {
                    ...eTagTransmissionSegment,
                  };
                  const { trans_alloc_profiles } = updatedTransmissionSegment;

                  if (trans_alloc_profiles !== null) {
                    const {
                      hasTransAllocId,
                      hasUpdated,
                      updatedTransAllocProfiles,
                    } = updateTransAllocProfilesFor(
                      transAllocId,
                      trans_alloc_profiles,
                      contract_number,
                    );

                    if (hasTransAllocId && physicalSegmentId !== undefined) {
                      updatedTransmissionSegment.physical_segment_id =
                        physicalSegmentId;
                      isUpdated = true;
                    }

                    if (hasUpdated) {
                      updatedTransmissionSegment.trans_alloc_profiles =
                        updatedTransAllocProfiles;
                      isUpdated = true;
                    }
                  }

                  if (isUpdated) {
                    updatedTransmissionSegments[transmissionSegmentsIndex] =
                      updatedTransmissionSegment;
                  }
                },
              );

              if (isUpdated) {
                updatedTransmission.transmission_segments =
                  updatedTransmissionSegments;
              }
            }

            if (isUpdated) {
              updatedPhysicalSegmentsProfiles.transmission =
                updatedTransmission;
            }
          }

          if (isUpdated) {
            updatedPhysicalSegmentsProfile.physical_segments_profiles =
              updatedPhysicalSegmentsProfiles;
          }
        }

        if (isUpdated) {
          updatedPhysicalSegmentsProfiles[physicalSegmentsProfileIndex] =
            updatedPhysicalSegmentsProfile;
        }
      },
    );

    return isUpdated
      ? updatedPhysicalSegmentsProfiles
      : physicalSegmentsProfiles;
  }

  return null;
};

const getKeyForProfileDataGridRow = (
  transAllocId: number,
  row: TProfileDataGridRow,
): string | undefined =>
  Object.keys(row).find((key: string): boolean => {
    const profileTransmission: ITransAllocProfile | undefined =
      getTransAllocProfileForKey(key);

    return (
      profileTransmission !== undefined &&
      profileTransmission.transAllocId === transAllocId
    );
  });

const updateProfileChanges = (
  profileChanges: TProfileDataGridRow[],
  transmissionAllocations: IETagTransmissionAllocation[] | null,
  previousTransmissionAllocations: IETagTransmissionAllocation[] | null,
  transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[] | null,
): TProfileDataGridRow[] => {
  const updatedProfileChanges: TProfileDataGridRow[] = [...profileChanges];
  const previousSortedTransmissionAllocations:
    | IETagTransmissionAllocation[]
    | undefined =
    previousTransmissionAllocations === null
      ? undefined
      : [...previousTransmissionAllocations].sort(transmissionAllocationSorter);
  const sortedTransmissionAllocations: IETagTransmissionAllocation[] =
    transmissionAllocations === null
      ? []
      : [...transmissionAllocations].sort(transmissionAllocationSorter);

  updatedProfileChanges.forEach((_, index: number) => {
    let previousPid: number | null = null;
    let transmissionName: string = '';

    sortedTransmissionAllocations.forEach(
      (transmissionAllocation: IETagTransmissionAllocation) => {
        const { physical_segment_ref, trans_alloc_id } = transmissionAllocation;
        const adjustedContractNumber: string = getAdjustedContractNumber(
          transmissionAllocation,
        );

        if (physical_segment_ref !== previousPid) {
          previousPid = physical_segment_ref;
          transmissionName = getTransmissionName(
            transmissionAllocation,
            transmissionPhysicalSegments,
          );
        }
        const key: string = getKeyForTransAllocProfile({
          adjustedContractNumber,
          physicalSegmentRef: physical_segment_ref,
          transAllocId: trans_alloc_id,
          transmissionName,
        });

        const rowKey: string | undefined = getKeyForProfileDataGridRow(
          trans_alloc_id,
          updatedProfileChanges[index],
        );

        if (rowKey === undefined) {
          updatedProfileChanges[index] = {
            ...updatedProfileChanges[index],
            [key]: null,
          };
        } else if (
          key !== rowKey &&
          previousSortedTransmissionAllocations !== undefined &&
          previousSortedTransmissionAllocations.find(
            (
              previousTransmissionAllocation: IETagTransmissionAllocation,
            ): boolean =>
              previousTransmissionAllocation === transmissionAllocation,
          ) === undefined
        ) {
          updatedProfileChanges[index] = {
            ...updatedProfileChanges[index],
            [key]: updatedProfileChanges[index][rowKey],
          };

          delete updatedProfileChanges[index][rowKey];
        }
      },
    );

    if (previousSortedTransmissionAllocations !== undefined) {
      previousSortedTransmissionAllocations.forEach(
        (previousTransmissionAllocation: IETagTransmissionAllocation) => {
          if (
            sortedTransmissionAllocations.find(
              (transmissionAllocation: IETagTransmissionAllocation): boolean =>
                previousTransmissionAllocation.trans_alloc_id ===
                transmissionAllocation.trans_alloc_id,
            ) === undefined
          ) {
            const rowKey: string | undefined = getKeyForProfileDataGridRow(
              previousTransmissionAllocation.trans_alloc_id,
              updatedProfileChanges[index],
            );

            if (rowKey !== undefined) {
              delete updatedProfileChanges[index][rowKey];
            }
          }
        },
      );
    }
  });

  return updatedProfileChanges;
};

const updateValidations = (
  validations: TDetailValidations,
  previousTransmissionAllocations: IETagTransmissionAllocation[],
  transmissionAllocations: IETagTransmissionAllocation[],
): TDetailValidations => {
  const updatedValidations: TDetailValidations = { ...validations };

  previousTransmissionAllocations.forEach(
    (transmissionAllocation: IETagTransmissionAllocation) => {
      delete updatedValidations[
        getTransmissionAllocationKey(transmissionAllocation)
      ];
    },
  );

  transmissionAllocations.forEach(
    (transmissionAllocation: IETagTransmissionAllocation) => {
      updatedValidations[getTransmissionAllocationKey(transmissionAllocation)] =
        isValidTransmissionAllocation(transmissionAllocation);
    },
  );

  return updatedValidations;
};

export const editOasisInfoToDetailState = (
  editOasisInfo: IEditOasisInfo,
): TStateTransform<IDetailState> => {
  return (detailState: IDetailState): IDetailState => {
    const updatedDetailState: IDetailState = { ...detailState };
    const updatedTransmissionAllocations: IETagTransmissionAllocation[] =
      updatedDetailState.transmissionAllocations === null
        ? []
        : [...updatedDetailState.transmissionAllocations];
    const {
      addAfterTransAllocId,
      editTransmissionAllocationMap,
      removeTransAllocId,
      withPhysicalSegmentRef,
    } = editOasisInfo;

    if (addAfterTransAllocId !== undefined) {
      let indexOfMatching: number = -1;

      updatedTransmissionAllocations.forEach(
        (
          transmissionAllocation: IETagTransmissionAllocation,
          index: number,
        ) => {
          if (transmissionAllocation.trans_alloc_id === addAfterTransAllocId) {
            indexOfMatching = index;
          }

          if (transmissionAllocation.trans_alloc_id > addAfterTransAllocId) {
            updatedTransmissionAllocations[index] = copyTransmissionAllocation(
              transmissionAllocation,
            );

            updatedTransmissionAllocations[index].trans_alloc_id += 1;
          }
        },
      );

      let newTransmissionAllocation: IETagTransmissionAllocation =
        getInitialTransmissionAllocation(addAfterTransAllocId + 1);
      newTransmissionAllocation.physical_segment_ref =
        withPhysicalSegmentRef === undefined ? null : withPhysicalSegmentRef;

      updatedTransmissionAllocations.splice(
        indexOfMatching + 1,
        0,
        newTransmissionAllocation,
      );

      updatedDetailState.transmissionAllocations =
        updatedTransmissionAllocations;

      updatedDetailState.physicalSegmentsProfiles =
        updatePhysicalSegmentsProfilesFor(
          addAfterTransAllocId,
          updatedDetailState.physicalSegmentsProfiles,
          updateTransAllocProfilesForAddAfter,
        );

      updatedDetailState.profileChanges = updateProfileChanges(
        updatedDetailState.profileChanges,
        updatedTransmissionAllocations,
        detailState.transmissionAllocations,
        detailState.transmission_physical_segments,
      );
    }

    if (editTransmissionAllocationMap !== undefined) {
      for (let transAllocKey in editTransmissionAllocationMap) {
        const editTransmissionAllocation: IEditTransmissionAllocation =
          editTransmissionAllocationMap[transAllocKey];
        const parsedTransAllocKey: number = parseInt(transAllocKey, 10);
        const trans_alloc_id: number =
          parsedTransAllocKey === INITIAL_RECORD_ID ? 1 : parsedTransAllocKey;
        const transmissionAllocationIndex: number =
          updatedTransmissionAllocations.findIndex(
            (transmissionAllocation: IETagTransmissionAllocation): boolean =>
              transmissionAllocation.trans_alloc_id === trans_alloc_id,
          );
        const updatedTransmissionAllocation: IETagTransmissionAllocation =
          transmissionAllocationIndex === -1
            ? getInitialTransmissionAllocation(trans_alloc_id)
            : copyTransmissionAllocation(
                updatedTransmissionAllocations[transmissionAllocationIndex],
              );

        const {
          contract_number,
          editMiscInfos,
          nits_resource,
          physical_segment_ref,
          trans_alloc_customer_code,
          trans_product_ref,
        } = editTransmissionAllocation;

        if (contract_number !== undefined) {
          updatedTransmissionAllocation.contract_number = contract_number;
        }

        if (editMiscInfos !== undefined) {
          const {
            addAfterMiscInfo,
            addToEndOfMiscInfos,
            editMiscInfoMap,
            removeMiscInfo,
          } = editMiscInfos;

          if (updatedTransmissionAllocation.misc_infos === null) {
            updatedTransmissionAllocation.misc_infos = [];
          } else {
            updatedTransmissionAllocation.misc_infos = [
              ...updatedTransmissionAllocation.misc_infos,
            ];
          }

          if (addAfterMiscInfo !== undefined) {
            let foundIndex: number = -1;
            let highestIndex: number = -1;

            updatedTransmissionAllocation.misc_infos.forEach(
              (miscInfo: IMiscInfo, index: number) => {
                const { editIndex } = getSplitEditInfoKey(miscInfo.key);

                if (editIndex === undefined) {
                  throw new Error(`Invalid miscInfo.key: ${miscInfo.key}`);
                }

                if (editIndex > highestIndex) {
                  highestIndex = editIndex;
                }

                if (miscInfo.key === addAfterMiscInfo) {
                  foundIndex = index;
                }
              },
            );

            if (foundIndex !== -1) {
              const newEditInfoKey: string = getEditInfoKey(
                getTransmissionAllocationKey(updatedTransmissionAllocation),
                trans_alloc_id,
                highestIndex + 1,
              );

              updatedTransmissionAllocation.misc_infos.splice(
                foundIndex + 1,
                0,
                getInitialMiscInfo(newEditInfoKey),
              );

              updatedDetailState.focusKey = newEditInfoKey;
            }
          }

          if (editMiscInfoMap !== undefined) {
            if (addToEndOfMiscInfos) {
              for (let miscInfoMapString in editMiscInfoMap) {
                const newEditInfoKey: string = getEditInfoKey(
                  getTransmissionAllocationKey(updatedTransmissionAllocation),
                  trans_alloc_id,
                  updatedTransmissionAllocation.misc_infos.length,
                );
                updatedTransmissionAllocation.misc_infos.push({
                  key: newEditInfoKey,
                  token: editMiscInfoMap[miscInfoMapString].token ?? null,
                  value: editMiscInfoMap[miscInfoMapString].value ?? null,
                });
              }
            } else {
              for (let miscInfoKey in editMiscInfoMap) {
                let miscInfoIndex: number =
                  updatedTransmissionAllocation.misc_infos.findIndex(
                    (miscInfo: IMiscInfo): boolean =>
                      miscInfo.key === miscInfoKey,
                  );
                let updatedMiscInfo: IMiscInfo;

                if (miscInfoIndex === -1) {
                  updatedMiscInfo = getInitialMiscInfo(miscInfoKey);
                  miscInfoIndex =
                    updatedTransmissionAllocation.misc_infos.length;
                } else {
                  updatedMiscInfo =
                    updatedTransmissionAllocation.misc_infos[miscInfoIndex];
                }

                updatedTransmissionAllocation.misc_infos[miscInfoIndex] = {
                  ...updatedMiscInfo,
                  ...editMiscInfoMap[miscInfoKey],
                };
              }

              updatedDetailState.focusKey = null;
            }
          }

          if (removeMiscInfo !== undefined) {
            const foundIndex: number =
              updatedTransmissionAllocation.misc_infos.findIndex(
                (miscInfo: IMiscInfo): boolean =>
                  miscInfo.key === removeMiscInfo,
              );

            if (foundIndex !== -1) {
              updatedTransmissionAllocation.misc_infos.splice(foundIndex, 1);
            }

            if (updatedTransmissionAllocation.misc_infos.length === 0) {
              updatedTransmissionAllocation.misc_infos = null;
            }
          }
        }

        if (nits_resource !== undefined) {
          updatedTransmissionAllocation.nits_resource = nits_resource;
        }

        if (physical_segment_ref !== undefined) {
          updatedTransmissionAllocation.physical_segment_ref =
            physical_segment_ref;
        }

        if (trans_alloc_customer_code !== undefined) {
          updatedTransmissionAllocation.trans_alloc_customer_code =
            trans_alloc_customer_code;
        }

        if (trans_product_ref !== undefined) {
          updatedTransmissionAllocation.trans_product_ref = trans_product_ref;
        }

        updatedTransmissionAllocations[
          transmissionAllocationIndex === -1
            ? updatedTransmissionAllocations.length
            : transmissionAllocationIndex
        ] = updatedTransmissionAllocation;

        updatedDetailState.physicalSegmentsProfiles =
          updatePhysicalSegmentsProfilesFor(
            trans_alloc_id,
            updatedDetailState.physicalSegmentsProfiles,
            updateTransAllocProfilesForEdit,
            physical_segment_ref,
            contract_number,
          );

        updatedDetailState.profileChanges = updateProfileChanges(
          updatedDetailState.profileChanges,
          updatedTransmissionAllocations,
          detailState.transmissionAllocations,
          detailState.transmission_physical_segments,
        );
      }

      updatedDetailState.transmissionAllocations =
        updatedTransmissionAllocations;
    }

    if (removeTransAllocId !== undefined) {
      const newUpdatedTransmissionAllocations: IETagTransmissionAllocation[] =
        [];

      updatedTransmissionAllocations.forEach(
        (transmissionAllocation: IETagTransmissionAllocation) => {
          if (transmissionAllocation.trans_alloc_id > removeTransAllocId) {
            const updatedTransmissionAllocation: IETagTransmissionAllocation =
              copyTransmissionAllocation(transmissionAllocation);

            updatedTransmissionAllocation.trans_alloc_id -= 1;

            newUpdatedTransmissionAllocations.push(
              updatedTransmissionAllocation,
            );
          } else if (
            transmissionAllocation.trans_alloc_id !== removeTransAllocId
          ) {
            newUpdatedTransmissionAllocations.push(transmissionAllocation);
          }

          updatedDetailState.transmissionAllocations =
            newUpdatedTransmissionAllocations;
        },
      );

      updatedDetailState.transmissionAllocations =
        newUpdatedTransmissionAllocations;

      updatedDetailState.physicalSegmentsProfiles =
        updatePhysicalSegmentsProfilesFor(
          removeTransAllocId,
          updatedDetailState.physicalSegmentsProfiles,
          updateTransAllocProfilesForRemove,
        );

      updatedDetailState.profileChanges = updateProfileChanges(
        updatedDetailState.profileChanges,
        newUpdatedTransmissionAllocations,
        updatedTransmissionAllocations,
        detailState.transmission_physical_segments,
      );

      updatedDetailState.validations = updateValidations(
        updatedDetailState.validations,
        updatedTransmissionAllocations,
        newUpdatedTransmissionAllocations,
      );
    }

    return updatedDetailState;
  };
};
