import ProfileDataGridColumnHeader from 'components/molecules/ProfileDataGridColumnHeader/ProfileDataGridColumnHeader';
import ProfileDataGridCellEdit from 'components/organisms/ProfileDataGridCellEdit/ProfileDataGridCellEdit';
import ProfileDataGridCellReview from 'components/organisms/ProfileDataGridCellReview/ProfileDataGridCellReview';
import {
  EDITOR_OPTIONS,
  PROFILE_CHANGE_ROW_EDIT_KEY_LABEL,
  PROFILE_PATH_RAMP_START_HEADER_NAME,
  PROFILE_PATH_RAMP_STOP_HEADER_NAME,
  PROFILE_PATH_START_HEADER_NAME,
  PROFILE_PATH_STOP_HEADER_NAME,
} from 'components/organisms/ProfileInformationView/constants';
import {
  checkRowId,
  isInitialProfileDataGridRow,
  stopDisabledDateForRow,
} from 'components/organisms/ProfileInformationView/helpers';
import {
  IEditProfileDataGrid,
  IEditProfileDataGridEditedValues,
  IEditProfileDataGridRow,
} from 'components/organisms/ProfileInformationView/types';
import {
  EDIT_ETAG_PHYSICAL_SEGMENTS_PROFILE_LABEL,
  INITIAL_RECORD_ID,
  PROFILE_PATH_RAMP_START_KEY,
  PROFILE_PATH_RAMP_STOP_KEY,
  PROFILE_PATH_START_KEY,
  PROFILE_PATH_STOP_KEY,
} from 'constants/Detail';
import {
  ID_KEY,
  LEFT_SEPARATOR_CLASS,
  RIGHT_SEPARATOR_CLASS,
} from 'constants/General';
import {
  DATA_GRID_DATA_COLUMN_WIDTH_VALUE,
  DATA_GRID_DATE_TIME_COLUMN_WIDTH_VALUE,
} from 'constants/styles';
import { EProfileDataGridCellType, ETableConfiguration } from 'enums/Detail';
import { ERequestType } from 'enums/ETag';
import { EViewMode } from 'enums/View';
import { permissionsForEncodedPermissionsId } from 'hooks/usePermissions';
import { IDataGridSelectionContext, IOption } from 'interfaces/Component';
import { IAdHocProfile, IProfileDataGridCell } from 'interfaces/Detail';
import {
  IETagTransmissionAllocation,
  IETagTransmissionPhysicalSegment,
} from 'interfaces/ETag';
import { IPermissions } from 'interfaces/Permissions';
import { IToEntity } from 'interfaces/ToEntity';
import { Context } from 'react';
import { EditorProps, FormatterProps } from 'react-data-grid';
import { IDetailLoadPhysicalSegment, IDetailState } from 'reduxes/Detail/types';
import { TTimeZone } from 'types/DateTime';
import {
  TProfileDataGridColumn,
  TProfileDataGridRow,
  TProfileDataGridSummaryRow,
} from 'types/Detail';
import {
  TDateTimeInputValidator,
  TDisabledDateHandler,
  TStateTransform,
} from 'types/General';
import {
  getAdjustedContractNumber,
  getEditInfoKey,
  getGenerationMarketLevelKey,
  getLoadMarketLevelKey,
  getSplitEditInfoKey,
  getStartStopDateTimesForAdHocProfile,
  getTransmissionName,
} from 'utils/detail';
import { encodeIds, isEmptyValue } from 'utils/general';
import { toFormattedUtcString } from 'utils/time';
import { ZonedDateTime } from 'utils/zonedDateTime';
import { getTransmissionColumns } from '../getTransmissionColumns.helpers';
import {
  getKeyForTransAllocProfile,
  getKeyForTransmissionEnergy,
} from 'utils/detail-energy';

export const getProfileChangeColumns = (
  requestType: ERequestType | undefined,
  generationPhysicalSegmentName: string,
  loadPhysicalSegment: IDetailLoadPhysicalSegment | null,
  loadPhysicalSegmentName: string,
  transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[] | null,
  sortedTransmissionAllocations: IETagTransmissionAllocation[],
  useUniqueProfiles: boolean,
  losses_v2: boolean | null,
  startDisabledDate?: TDisabledDateHandler,
  startDateTimeInputValidator?: TDateTimeInputValidator,
  initialProfileChanges?: TProfileDataGridRow[],
): TProfileDataGridColumn[] => {
  const getEditor =
    (DataGridSelectionContext: Context<IDataGridSelectionContext>) =>
    (
      props: EditorProps<TProfileDataGridRow, TProfileDataGridSummaryRow>,
    ): JSX.Element =>
      (
        <ProfileDataGridCellEdit
          {...props}
          alwaysAllowEdit={true}
          DataGridSelectionContext={DataGridSelectionContext}
          initialDataSet={initialProfileChanges}
        />
      );
  const getFormatter =
    (DataGridSelectionContext: Context<IDataGridSelectionContext>) =>
    (
      props: FormatterProps<TProfileDataGridRow, TProfileDataGridSummaryRow>,
    ): JSX.Element =>
      (
        <ProfileDataGridCellReview
          {...props}
          DataGridSelectionContext={DataGridSelectionContext}
          isAlwaysChanged={true}
          initialDataSet={initialProfileChanges}
          isProfileChange={true}
        />
      );
  const headerRenderer = ProfileDataGridColumnHeader;

  let profileChangeColumns: TProfileDataGridColumn[] = [
    {
      editorOptions: EDITOR_OPTIONS,
      frozen: true,
      getEditor:
        (DataGridSelectionContext: Context<IDataGridSelectionContext>) =>
        (
          props: EditorProps<TProfileDataGridRow, TProfileDataGridSummaryRow>,
        ): JSX.Element =>
          (
            <ProfileDataGridCellEdit
              {...props}
              alwaysAllowEdit={true}
              DataGridSelectionContext={DataGridSelectionContext}
              dateTimeInputValidator={startDateTimeInputValidator}
              disabledDate={startDisabledDate}
              initialDataSet={initialProfileChanges}
            />
          ),
      getFormatter,
      headerRenderer,
      key: PROFILE_PATH_START_KEY,
      name: PROFILE_PATH_START_HEADER_NAME,
      width: DATA_GRID_DATE_TIME_COLUMN_WIDTH_VALUE,
    },
    {
      editorOptions: EDITOR_OPTIONS,
      frozen: true,
      getEditor:
        (DataGridSelectionContext: Context<IDataGridSelectionContext>) =>
        (
          props: EditorProps<TProfileDataGridRow, TProfileDataGridSummaryRow>,
        ): JSX.Element =>
          (
            <ProfileDataGridCellEdit
              {...props}
              alwaysAllowEdit={true}
              DataGridSelectionContext={DataGridSelectionContext}
              disabledDate={stopDisabledDateForRow(props.row)}
              initialDataSet={initialProfileChanges}
            />
          ),
      getFormatter,
      headerRenderer,
      key: PROFILE_PATH_STOP_KEY,
      name: PROFILE_PATH_STOP_HEADER_NAME,
      width: DATA_GRID_DATE_TIME_COLUMN_WIDTH_VALUE,
    },
  ];

  if (
    requestType === ERequestType.AtfAdjustment ||
    requestType === ERequestType.BtfAdjustment ||
    requestType === ERequestType.Curtailment
  ) {
    profileChangeColumns.push({
      cellClass: RIGHT_SEPARATOR_CLASS,
      editorOptions: EDITOR_OPTIONS,
      getEditor,
      getFormatter,
      headerCellClass: RIGHT_SEPARATOR_CLASS,
      headerRenderer,
      key: getGenerationMarketLevelKey(generationPhysicalSegmentName),
      name: `Gen\n${generationPhysicalSegmentName}\nRequest`,
      resizable: true,
      width: DATA_GRID_DATA_COLUMN_WIDTH_VALUE,
      editable: true,
    });

    profileChangeColumns = profileChangeColumns.concat(
      getTransmissionColumns(
        transmissionPhysicalSegments,
        sortedTransmissionAllocations,
        ETableConfiguration.ProfileOnly,
        useUniqueProfiles,
        getFormatter,
        getFormatter,
        getEditor,
        undefined,
        losses_v2 ?? undefined,
        undefined,
      ),
    );

    if (losses_v2) {
      profileChangeColumns.push({
        cellClass: LEFT_SEPARATOR_CLASS,
        editorOptions: EDITOR_OPTIONS,
        getEditor,
        getFormatter,
        headerCellClass: LEFT_SEPARATOR_CLASS,
        headerRenderer,
        key: getLoadMarketLevelKey(loadPhysicalSegmentName),
        name: `Load\n${loadPhysicalSegmentName}\nRequest`,
        resizable: true,
        width: DATA_GRID_DATA_COLUMN_WIDTH_VALUE,
        editable: true,
      });
    }

    // In order to allow a unique energy profile for the load, we need to check
    // that its profile_ref matches its physical_segment_id. Typically, the
    // load profile_ref will refer to the previous transmission's POD
    // profile_ref, which is the same as the previous transmissions's
    // physical_segment_id and NOT the load's physical_segment_id
    if (
      useUniqueProfiles &&
      loadPhysicalSegment !== null &&
      loadPhysicalSegment.profile_ref ===
        loadPhysicalSegment.physical_segment_id
    ) {
      profileChangeColumns = profileChangeColumns.concat({
        cellClass: LEFT_SEPARATOR_CLASS,
        editorOptions: EDITOR_OPTIONS,
        getEditor,
        getFormatter,
        headerCellClass: LEFT_SEPARATOR_CLASS,
        headerRenderer: ProfileDataGridColumnHeader,
        key: getLoadMarketLevelKey(loadPhysicalSegmentName),
        name: `Load\n${loadPhysicalSegmentName}\nRequest`,
        resizable: true,
        width: DATA_GRID_DATA_COLUMN_WIDTH_VALUE,
      });
    }

    profileChangeColumns.push(
      {
        editorOptions: EDITOR_OPTIONS,
        getEditor,
        getFormatter,
        headerRenderer,
        key: PROFILE_PATH_RAMP_START_KEY,
        name: PROFILE_PATH_RAMP_START_HEADER_NAME,
        width: DATA_GRID_DATA_COLUMN_WIDTH_VALUE,
      },
      {
        cellClass: RIGHT_SEPARATOR_CLASS,
        editorOptions: EDITOR_OPTIONS,
        getEditor,
        getFormatter,
        headerCellClass: RIGHT_SEPARATOR_CLASS,
        headerRenderer,
        key: PROFILE_PATH_RAMP_STOP_KEY,
        name: PROFILE_PATH_RAMP_STOP_HEADER_NAME,
        width: DATA_GRID_DATA_COLUMN_WIDTH_VALUE,
      },
    );
  }

  return profileChangeColumns;
};

export const getInitialProfileChangeRow = (
  primaryId: number,
  generationMarketLevelName: string,
  loadMarketLevelName: string,
  sortedTransmissionAllocations: IETagTransmissionAllocation[],
  transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[] | null,
  profileStart?: string,
  profileStop?: string,
  genRequest?: number | null,
): TProfileDataGridRow => {
  const initialRow: TProfileDataGridRow = {
    id: {
      type: EProfileDataGridCellType.String,
      value: getEditInfoKey(PROFILE_CHANGE_ROW_EDIT_KEY_LABEL, primaryId, 0),
    },
    [PROFILE_PATH_START_KEY]: {
      type: EProfileDataGridCellType.DateTimeString,
      value: profileStart === undefined ? '' : profileStart,
    },
    [PROFILE_PATH_STOP_KEY]: {
      type: EProfileDataGridCellType.DateTimeString,
      value: profileStop === undefined ? '' : profileStop,
    },
    [PROFILE_PATH_RAMP_START_KEY]: null,
    [PROFILE_PATH_RAMP_STOP_KEY]: null,
    [generationMarketLevelName]:
      genRequest === undefined || genRequest === null
        ? null
        : {
            type: EProfileDataGridCellType.Number,
            value: genRequest,
          },
  };

  transmissionPhysicalSegments?.forEach((transmissionPhysicalSegment) => {
    initialRow[
      getKeyForTransmissionEnergy({
        physicalSegmentRef: transmissionPhysicalSegment.physical_segment_id,
        porPodLossOrAllocTotals: 'POD',
      })
    ] = null;
  });

  let previousPid: number | null = null;
  let transmissionName: string = '';

  sortedTransmissionAllocations.forEach(
    (transmissionAllocation: IETagTransmissionAllocation) => {
      const { physical_segment_ref, trans_alloc_id } = transmissionAllocation;
      const adjustedContractNumber: string = getAdjustedContractNumber(
        transmissionAllocation,
      );

      if (physical_segment_ref !== previousPid) {
        previousPid = physical_segment_ref;
        transmissionName = getTransmissionName(
          transmissionAllocation,
          transmissionPhysicalSegments,
        );
      }

      const key: string = getKeyForTransAllocProfile({
        adjustedContractNumber,
        physicalSegmentRef: physical_segment_ref,
        transAllocId: trans_alloc_id,
        transmissionName,
      });

      // initialRow[`${key}:${TRANSMISSION_POD_KEY_SUFFIX}`] = null;
      initialRow[key] = null;
    },
  );

  initialRow[loadMarketLevelName] = null;

  return initialRow;
};

const addRow = (
  existingRow: TProfileDataGridRow,
  after: boolean,
  rows: TProfileDataGridRow[],
  generationPhysicalSegmentName: string,
  loadPhysicalSegmentName: string,
  sortedTransmissionAllocations: IETagTransmissionAllocation[],
  transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[] | null,
): TProfileDataGridRow[] => {
  checkRowId(existingRow);

  let foundIndex: number = -1;
  let highestPrimaryId: number = -1;

  rows.forEach((row: TProfileDataGridRow, index: number) => {
    const { primaryId } = getSplitEditInfoKey(row.id!.value as string);

    if (primaryId === undefined) {
      throw new Error(`Invalid row id: ${row.id!.value}`);
    }

    if (primaryId > highestPrimaryId) {
      highestPrimaryId = primaryId;
    }

    if (row.id!.value === existingRow.id!.value) {
      foundIndex = index;
    }
  });
  if (foundIndex !== -1) {
    const updatedRows: TProfileDataGridRow[] = [...rows];

    updatedRows.splice(
      after ? foundIndex + 1 : foundIndex,
      0,
      getInitialProfileChangeRow(
        highestPrimaryId === INITIAL_RECORD_ID ? 1 : highestPrimaryId + 1,
        getGenerationMarketLevelKey(generationPhysicalSegmentName),
        getLoadMarketLevelKey(loadPhysicalSegmentName),
        sortedTransmissionAllocations,
        transmissionPhysicalSegments,
      ),
    );

    return updatedRows;
  }

  return rows;
};

const adjustStartStop = (
  row: TProfileDataGridRow,
  timeZone: TTimeZone,
): TProfileDataGridRow => {
  const startCell: IProfileDataGridCell | null | undefined =
    row[PROFILE_PATH_START_KEY];

  if (startCell === undefined) {
    throw new Error(
      `Missing key: ${PROFILE_PATH_START_KEY} for row: ${JSON.stringify(row)}`,
    );
  }

  const stopCell: IProfileDataGridCell | null | undefined =
    row[PROFILE_PATH_STOP_KEY];

  if (stopCell === undefined) {
    throw new Error(
      `Missing key: ${PROFILE_PATH_STOP_KEY} for row: ${JSON.stringify(row)}`,
    );
  }

  if (startCell !== null && stopCell !== null) {
    if (startCell.type !== EProfileDataGridCellType.DateTimeString) {
      throw new Error(
        `Invalid profile start cell for profile row: ${JSON.stringify(row)}`,
      );
    }

    if (stopCell.type !== EProfileDataGridCellType.DateTimeString) {
      throw new Error(
        `Invalid profile stop cell for profile row: ${JSON.stringify(row)}`,
      );
    }

    const startCellValue: string = startCell.value as string;
    const stopCellValue: string = stopCell.value as string;

    if (!isEmptyValue(startCellValue) && !isEmptyValue(stopCellValue)) {
      const startDateTime: ZonedDateTime = ZonedDateTime.parseIso(
        startCellValue,
        timeZone,
      );
      const stopDateTime: ZonedDateTime = ZonedDateTime.parseIso(
        stopCellValue,
        timeZone,
      );

      if (startDateTime.isSameOrAfter(stopDateTime)) {
        return {
          ...row,
          [PROFILE_PATH_STOP_KEY]: {
            type: EProfileDataGridCellType.DateTimeString,
            value: '',
          },
        };
      }
    }
  }

  return row;
};

export const editProfileDataGridToDetailState = (
  editProfileDataGrid: IEditProfileDataGrid,
  generationPhysicalSegmentName: string,
  loadPhysicalSegmentName: string,
  sortedTransmissionAllocations: IETagTransmissionAllocation[],
  transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[] | null,
  timeZone: TTimeZone,
): TStateTransform<IDetailState> => {
  return (detailState: IDetailState): IDetailState => {
    const { profileChanges } = detailState;
    const {
      addAfterRow,
      addBeforeRow,
      addExtraRows,
      editProfileDataGridRows,
      removeRows,
      removeAfterRow,
      removeBeforeRow,
    } = editProfileDataGrid;
    let updatedProfileChanges: TProfileDataGridRow[] = profileChanges;
    let hasUpdated: boolean = false;

    if (addAfterRow !== undefined) {
      updatedProfileChanges = addRow(
        addAfterRow,
        true,
        updatedProfileChanges,
        generationPhysicalSegmentName,
        loadPhysicalSegmentName,
        sortedTransmissionAllocations,
        transmissionPhysicalSegments,
      );

      hasUpdated = updatedProfileChanges !== profileChanges;
    }

    if (addBeforeRow !== undefined) {
      updatedProfileChanges = addRow(
        addBeforeRow,
        false,
        updatedProfileChanges,
        generationPhysicalSegmentName,
        loadPhysicalSegmentName,
        sortedTransmissionAllocations,
        transmissionPhysicalSegments,
      );

      hasUpdated = updatedProfileChanges !== profileChanges;
    }

    if (editProfileDataGridRows !== undefined) {
      editProfileDataGridRows.forEach(
        (editProfileDataGridRow: IEditProfileDataGridRow) => {
          const { isSameTransAllocProfile, row, updatedRow } =
            editProfileDataGridRow;
          let adjustedUpdatedRow: TProfileDataGridRow = updatedRow;

          checkRowId(row);

          if (isSameTransAllocProfile) {
            throw new Error(
              'Invalid editProfileDataGridRow, unsupported isSameTransAllocProfile: true',
            );
          }

          adjustedUpdatedRow = adjustStartStop(adjustedUpdatedRow, timeZone);

          const foundIndex: number = updatedProfileChanges.findIndex(
            (updatedProfileChangesRow: TProfileDataGridRow): boolean =>
              updatedProfileChangesRow.id!.value === row.id!.value,
          );

          if (foundIndex === -1) {
            // Edits must always occur on existing rows. The only exception is
            // when there are no existing profileChanges.
            if (updatedProfileChanges.length > 0) {
              throw new Error(
                `Invalid editProfileDataGridRow: ${JSON.stringify(
                  editProfileDataGridRow,
                )} for profileChanges: ${JSON.stringify(
                  updatedProfileChanges,
                )}`,
              );
            }

            // We need to remove the INITIAl_RECORD_ID to ensure that it is
            // correctly identified
            if (isInitialProfileDataGridRow(adjustedUpdatedRow)) {
              adjustedUpdatedRow = {
                ...adjustedUpdatedRow,
                id: {
                  type: EProfileDataGridCellType.String,
                  value: getEditInfoKey(
                    EDIT_ETAG_PHYSICAL_SEGMENTS_PROFILE_LABEL,
                    1,
                    0,
                  ),
                },
              };
            }

            updatedProfileChanges = [adjustedUpdatedRow];
          } else {
            updatedProfileChanges = [...updatedProfileChanges];
            updatedProfileChanges[foundIndex] = adjustedUpdatedRow;
          }

          hasUpdated = true;
        },
      );
    }

    if (addExtraRows !== undefined) {
      let highestPrimaryId: number = -1;

      updatedProfileChanges = [...updatedProfileChanges];

      updatedProfileChanges.forEach(
        (profileDataGridRow: TProfileDataGridRow) => {
          const rowId: IProfileDataGridCell | null | undefined =
            profileDataGridRow[ID_KEY];

          if (rowId === null || rowId === undefined) {
            throw new Error(
              `Missing id for profileDataGridRow: ${JSON.stringify(
                profileDataGridRow,
              )}`,
            );
          } else if (rowId.type !== EProfileDataGridCellType.String) {
            throw new Error(
              `Invalid id cell type for profileDataGridRow: ${JSON.stringify(
                profileDataGridRow,
              )}`,
            );
          }

          const { primaryId } = getSplitEditInfoKey(rowId.value as string);

          if (primaryId === undefined) {
            throw new Error(
              `Invalid id for profileDataGridRow: ${JSON.stringify(
                profileDataGridRow,
              )}`,
            );
          }

          if (primaryId > highestPrimaryId) {
            highestPrimaryId = primaryId;
          }

          hasUpdated = true;
        },
      );

      addExtraRows.forEach(
        (profileDataGridRow: TProfileDataGridRow, index: number) => {
          const newProfileDataGridRow: TProfileDataGridRow =
            getInitialProfileChangeRow(
              highestPrimaryId + index + 1,
              getGenerationMarketLevelKey(generationPhysicalSegmentName),
              getLoadMarketLevelKey(loadPhysicalSegmentName),
              sortedTransmissionAllocations,
              transmissionPhysicalSegments,
            );

          Object.keys(profileDataGridRow).forEach((key: string) => {
            newProfileDataGridRow[key] = profileDataGridRow[key];
          });

          updatedProfileChanges.push(newProfileDataGridRow);
        },
      );
    }

    if (removeRows !== undefined) {
      const { fromRow, toRow } = removeRows;

      updatedProfileChanges = [...updatedProfileChanges];

      updatedProfileChanges.splice(fromRow, toRow - fromRow + 1);

      hasUpdated = true;
    }

    if (removeAfterRow !== undefined) {
      const retainedProfileChanges: TProfileDataGridRow[] = [];

      for (let i: number = 0; i < updatedProfileChanges.length; i += 1) {
        const profileDataGridRow: TProfileDataGridRow =
          updatedProfileChanges[i];

        retainedProfileChanges.push(profileDataGridRow);

        if (profileDataGridRow.id!.value === removeAfterRow.id!.value) {
          break;
        }
      }

      updatedProfileChanges = retainedProfileChanges;

      hasUpdated = true;
    }

    if (removeBeforeRow !== undefined) {
      const retainedProfileChanges: TProfileDataGridRow[] = [];
      let includePhysicalSegmentsProfile: boolean = false;

      for (let i: number = 0; i < updatedProfileChanges.length; i += 1) {
        const profileDataGridRow: TProfileDataGridRow =
          updatedProfileChanges[i];

        if (profileDataGridRow.id!.value === removeBeforeRow.id!.value) {
          includePhysicalSegmentsProfile = true;
        }

        if (includePhysicalSegmentsProfile) {
          retainedProfileChanges.push(profileDataGridRow);
        }
      }

      updatedProfileChanges = retainedProfileChanges;

      hasUpdated = true;
    }

    return hasUpdated
      ? { ...detailState, profileChanges: updatedProfileChanges }
      : detailState;
  };
};

export const getRequestTypeOptions = (
  id: string,
  toEntity: IToEntity | null,
  viewMode: EViewMode,
  hideAtf: boolean,
  hideBtf: boolean,
): IOption<ERequestType>[] => {
  if (isEmptyValue(id) || toEntity === null) {
    return [];
  }

  const requestTypeOptions: IOption<ERequestType>[] = [];
  const adjustmentPermissions: IPermissions =
    permissionsForEncodedPermissionsId(encodeIds([id, 'adjustment']), [
      toEntity,
    ]);
  const curtailmentReloadPermissions: IPermissions =
    permissionsForEncodedPermissionsId(encodeIds([id, 'curtailmentReload']), [
      toEntity,
    ]);

  if (adjustmentPermissions.isDisplayable) {
    if (!hideBtf) {
      requestTypeOptions.push({
        isDisabled: !adjustmentPermissions.isExecutable,
        label: 'Before The Fact Adjustment',
        value: ERequestType.BtfAdjustment,
      });
    }

    if (viewMode === EViewMode.EditETagAdjustmentWithATF && !hideAtf) {
      requestTypeOptions.push({
        isDisabled: !adjustmentPermissions.isExecutable,
        label: 'After The Fact Adjustment',
        value: ERequestType.AtfAdjustment,
      });
    }
  }

  if (curtailmentReloadPermissions.isDisplayable) {
    requestTypeOptions.push(
      {
        isDisabled: !curtailmentReloadPermissions.isExecutable,
        label: 'Curtailment',
        value: ERequestType.Curtailment,
      },
      {
        isDisabled: !curtailmentReloadPermissions.isExecutable,
        label: 'Reload',
        value: ERequestType.Reload,
      },
    );
  }

  return requestTypeOptions;
};

const isProfileRowEmpty = (row: any): boolean => {
  for (const [key, value] of Object.entries(row)) {
    const prop = value as any;
    let isEmpty = true;
    if (key !== 'id' && key !== 'path-start' && key !== 'path-stop') {
      if (prop) {
        if (prop.type === 'number' && prop.value > 0) {
          isEmpty = false;
        }
      }
    }

    if (!isEmpty) {
      return false;
    }
  }
  return true;
};

const profileChangesDateSorter = (
  a: TProfileDataGridRow,
  b: TProfileDataGridRow,
) => {
  const aStartDate = a[PROFILE_PATH_START_KEY];
  const bStartDate = b[PROFILE_PATH_START_KEY];
  if (a && b && aStartDate && bStartDate) {
    if (
      typeof aStartDate.value === 'string' &&
      typeof bStartDate.value === 'string'
    ) {
      const aStartDateTime = ZonedDateTime.parseIso(
        aStartDate.value,
        'America/Los_Angeles',
      );
      const bStartDateTime = ZonedDateTime.parseIso(
        bStartDate.value,
        'America/Los_Angeles',
      );
      if (aStartDateTime.isBefore(bStartDateTime)) {
        return -1;
      }
      if (aStartDateTime.isAfter(bStartDateTime)) {
        return 1;
      }
      return 0;
    }
  }
  return 0;
};

export const adHocProfilesToDetailState = (
  adHocProfile: IAdHocProfile,
  generationPhysicalSegmentName: string,
  loadPhysicalSegmentName: string,
  sortedTransmissionAllocations: IETagTransmissionAllocation[],
  transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[] | null,
  editedValues?: IEditProfileDataGridEditedValues,
  setUpdatedGridRows?: (rows: any[]) => void,
): TStateTransform<IDetailState> => {
  return (detailState: IDetailState): IDetailState => {
    const updatedGridRows: any[] = [];

    const { genValues, startStopDateTimes } =
      getStartStopDateTimesForAdHocProfile(adHocProfile);
    const profileChanges: TProfileDataGridRow[] = [];
    let primaryId: number = 1;

    if (editedValues && !adHocProfile.fromDateChange) {
      editedValues.originalRows.forEach((value) => {
        profileChanges.push(value.updatedRow);
      });
    }

    for (
      let i: number = 0, j: number = 0;
      i < startStopDateTimes.length;
      i += 2, j += 1
    ) {
      const start: ZonedDateTime = startStopDateTimes[i];
      const stop: ZonedDateTime = startStopDateTimes[i + 1];

      let profileChangeRow = getInitialProfileChangeRow(
        primaryId,
        getGenerationMarketLevelKey(generationPhysicalSegmentName),
        getLoadMarketLevelKey(loadPhysicalSegmentName),
        sortedTransmissionAllocations,
        transmissionPhysicalSegments,
        toFormattedUtcString(start),
        toFormattedUtcString(stop),
        genValues[j],
      );

      if (
        editedValues &&
        !adHocProfile.skipEditedValues &&
        !adHocProfile.fromDateChange
      ) {
        if (
          start.isSameOrAfter(editedValues.startDateTime) &&
          stop.isSameOrBefore(editedValues.stopDateTime)
        ) {
          // We can disable this loop rule check because the functions we're creating here
          // aren't actually stored and used/called again manually, they are just
          // callbacks for other forEach loops
          //eslint-disable-next-line no-loop-func
          editedValues.carryOverValues.forEach((value) => {
            if (
              start.getHour() === value.startDate?.getHour() &&
              start.getMinute() === value.startDate?.getMinute()
            ) {
              const keys = Object.keys(value.editedRow.updatedRow);
              let carryOverRow: TProfileDataGridRow = {};
              keys.forEach((key) => {
                carryOverRow[key] = value.editedRow.updatedRow[key];
              });
              if (carryOverRow) {
                const pathStart = profileChangeRow[PROFILE_PATH_START_KEY];
                const pathStop = profileChangeRow[PROFILE_PATH_STOP_KEY];
                profileChangeRow = carryOverRow;
                profileChangeRow['id'] = {
                  type: EProfileDataGridCellType.String,
                  value: `${PROFILE_CHANGE_ROW_EDIT_KEY_LABEL}:${primaryId}:0`,
                };
                profileChangeRow[PROFILE_PATH_START_KEY] = pathStart;
                profileChangeRow[PROFILE_PATH_STOP_KEY] = pathStop;
              }
            }
          });
        }
        const isRowEmpty = isProfileRowEmpty(profileChangeRow);
        if (!isRowEmpty) {
          const currentProfileRowId = profileChangeRow[PROFILE_PATH_START_KEY];
          const currentProfileRowIndex = profileChanges.findIndex(
            (row) =>
              row[PROFILE_PATH_START_KEY]?.value === currentProfileRowId?.value,
          );
          if (currentProfileRowIndex === -1) {
            profileChanges.push(profileChangeRow);
            updatedGridRows.push({ updatedRow: profileChangeRow });
          }
          primaryId += 1;
        }
      } else {
        if (editedValues) {
          const originalRows = editedValues.originalRows.map(
            (row) => row.updatedRow,
          );
          const rowExistsIndex = originalRows.findIndex(
            (row) =>
              row[PROFILE_PATH_START_KEY]?.value ===
              profileChangeRow[PROFILE_PATH_START_KEY]?.value,
          );
          if (rowExistsIndex > -1) {
            profileChangeRow = originalRows[rowExistsIndex];
          }
        }
        profileChanges.push(profileChangeRow);
        primaryId += 1;
      }
    }

    if (setUpdatedGridRows) {
      if (editedValues?.originalRows) {
        setUpdatedGridRows(editedValues?.originalRows.concat(updatedGridRows));
      } else {
        setUpdatedGridRows(updatedGridRows);
      }
    }
    profileChanges.sort(profileChangesDateSorter);
    return { ...detailState, profileChanges };
  };
};
