import {
  TRANSMISSION_KEY_REG_EXP,
  TRANSMISSION_PHYSICAL_SEGMENT_ID_SPECIAL_KEY,
} from 'constants/Detail';
import { ITransAllocProfile } from 'interfaces/Detail';

export interface ITransmissionEnergy {
  physicalSegmentRef: number;
  porPodLossOrAllocTotals: 'POR' | 'POD' | 'LOSS' | 'ALLOC_TOTALS';
}

export const getKeyForTransmissionEnergy = ({
  physicalSegmentRef,
  porPodLossOrAllocTotals,
}: ITransmissionEnergy) => {
  return `ps${physicalSegmentRef}-${porPodLossOrAllocTotals}`;
};

export const getTransmissionEnergyForKey = (
  key: string,
): ITransmissionEnergy => {
  const [physicalSegmentRefWithPs, porPodLossOrAllocTotals] = key.split('-');
  const physicalSegmentRef = parseInt(
    physicalSegmentRefWithPs.replace('ps', ''),
  );

  return {
    physicalSegmentRef: physicalSegmentRef,
    porPodLossOrAllocTotals: porPodLossOrAllocTotals as
      | 'POR'
      | 'POD'
      | 'LOSS'
      | 'ALLOC_TOTALS',
  };
};

export const getPhysicalSegmentRefForTransmissionEnergyPodKey = (
  key: string,
): number | null | undefined => {
  const physicalSegmentRefString = /ps(\d+)/.exec(key)?.[1];
  const physicalSegmentRef = physicalSegmentRefString
    ? parseInt(physicalSegmentRefString, 10)
    : null;

  return physicalSegmentRef;
};

export const getKeyForTransAllocProfile = ({
  transAllocId,
  physicalSegmentRef,
  transmissionName,
  adjustedContractNumber,
}: ITransAllocProfile): string =>
  `ta${transAllocId}:ps${
    physicalSegmentRef === null
      ? TRANSMISSION_PHYSICAL_SEGMENT_ID_SPECIAL_KEY
      : physicalSegmentRef
  }:${transmissionName}:${adjustedContractNumber}`;

export const getTransAllocProfileForKey = (
  profileTransmissionKey: string,
): ITransAllocProfile | undefined => {
  const matches: RegExpMatchArray | null = profileTransmissionKey.match(
    TRANSMISSION_KEY_REG_EXP,
  );

  if (matches !== null) {
    return {
      adjustedContractNumber: matches[4],
      physicalSegmentRef:
        matches[2] === TRANSMISSION_PHYSICAL_SEGMENT_ID_SPECIAL_KEY
          ? null
          : parseInt(matches[2], 10),
      transAllocId: parseInt(matches[1], 10),
      transmissionName: matches[3],
    };
  }

  return undefined;
};
