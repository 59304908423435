import ProfileDataGridColumnHeader from 'components/molecules/ProfileDataGridColumnHeader/ProfileDataGridColumnHeader';
import { TRANSMISSION_PHYSICAL_SEGMENT_ID_SPECIAL_KEY } from 'constants/Detail';
import { LEFT_SEPARATOR_CLASS, RIGHT_SEPARATOR_CLASS } from 'constants/General';
import { DATA_GRID_DATA_COLUMN_WIDTH_VALUE } from 'constants/styles';
import { ETableConfiguration } from 'enums/Detail';
import { IDataGridSelectionContext } from 'interfaces/Component';
import {
  IETagTransmissionAllocation,
  IETagTransmissionPhysicalSegment,
} from 'interfaces/ETag';
import { Context } from 'react';
import {
  EditorProps,
  FormatterProps,
  SummaryFormatterProps,
} from 'react-data-grid';
import {
  TProfileDataGridColumn,
  TProfileDataGridRow,
  TProfileDataGridSummaryRow,
} from 'types/Detail';
import { getAdjustedContractNumber, getTransmissionName } from 'utils/detail';
import {
  getKeyForTransAllocProfile,
  getKeyForTransmissionEnergy,
} from 'utils/detail-energy';
import {
  CELL_DISABLED_CLASS,
  CELL_TOTALS_CLASS,
  EDITOR_OPTIONS,
  TRANSMISSION_CONTRACT_NUMBER_KEY_REG_EXP,
} from './constants';

export interface IContracts {
  contract: string | null;
  product_name?: string;
}
interface ISegment {
  segment: number | null;
  count: number;
  contracts: IContracts[];
}

const sortRecordByKey = <K extends string | number, V>(
  record: Record<K, V>,
): Record<K, V> =>
  Object.fromEntries(
    Object.entries(record).sort(([keyA], [keyB]) =>
      keyA.toString().localeCompare(keyB.toString()),
    ),
  ) as Record<K, V>;

const displaySingleContractNumber = (
  adjustedContractNumber: string,
): string => {
  return TRANSMISSION_CONTRACT_NUMBER_KEY_REG_EXP.exec(
    adjustedContractNumber,
  ) === null
    ? adjustedContractNumber
    : '';
};

const displayMultipleContractNumbers = (contracts: IContracts[]): string => {
  let joinedContracts = '';
  if (contracts) {
    contracts.forEach((contract) => {
      joinedContracts += `<div style="flex: 1; width: 70px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;" title="${contract.contract} (${contract.product_name})">${contract.contract}</div>`;
    });
  }
  return `<div style="display: flex; flex-direction: row; gap: 10px;">${joinedContracts}</div>`;
};

const displayAdjustedContractNumber = (
  adjustedContractNumber: string,
  contracts: IContracts[],
  hasMultipleContracts = false,
  previewMode = false,
): string => {
  if (!hasMultipleContracts || previewMode) {
    return displaySingleContractNumber(adjustedContractNumber);
  }
  return displayMultipleContractNumbers(contracts);
};

// Helper function to calculate column span for grouped headers
const getColSpan = (props: any, colSpan: number) => {
  return props.type === 'HEADER' ? colSpan : 1;
};

const getColumnNameBase = (
  transmissionCode: string | undefined,
  transmissionName: string,
  physicalSegmentRefString: string,
  suffix: string,
) =>
  `${
    transmissionCode ?? ''
  }\n${transmissionName}\n${suffix} for T${physicalSegmentRefString}`;

const getTransAllocTotalsColumnName = (
  transmissionCode: string | undefined,
  transmissionName: string,
  physicalSegmentRefString: string,
) =>
  getColumnNameBase(
    transmissionCode,
    transmissionName,
    physicalSegmentRefString,
    'Alloc-Totals',
  );

const getSegmentColumnName = (
  transmissionCode: string | undefined,
  adjustedContractNumber: string,
  currentSegment: ISegment | undefined,
  productName: string,
  transmissionPhysicalSegment: IETagTransmissionPhysicalSegment | undefined,
  isPrintView?: boolean,
) =>
  `${transmissionCode ?? ''}\n\n${displayAdjustedContractNumber(
    adjustedContractNumber,
    currentSegment ? currentSegment.contracts : [],
    currentSegment && currentSegment.count > 1,
    isPrintView,
  )}${productName}\n\n${transmissionPhysicalSegment?.physical_segment_id}`;

const getPhysicalSegmentRefString = (physicalSegmentRef: number | null) =>
  physicalSegmentRef?.toString() ??
  TRANSMISSION_PHYSICAL_SEGMENT_ID_SPECIAL_KEY;

const getTooltipText = (
  transmissionCode: string | undefined,
  adjustedContractNumber: string,
  currentSegment: ISegment | undefined,
  productName: string,
) =>
  `${transmissionCode ?? ''}\n\n${displayAdjustedContractNumber(
    adjustedContractNumber,
    [],
    currentSegment && currentSegment.count > 1,
    true,
  )}${productName}`;

const getBaseColumnAttributes = (
  key: string,
  transmissionCode: string | undefined,
  transmissionName: string,
  physicalSegmentRefString: string,
  nameSuffix: string,
  getCurrentFormatter?: (
    DataGridSelectionContext: Context<IDataGridSelectionContext>,
  ) => (
    props: FormatterProps<TProfileDataGridRow, TProfileDataGridSummaryRow>,
  ) => JSX.Element,
  summaryFormatter?: (
    props: SummaryFormatterProps<
      TProfileDataGridSummaryRow,
      TProfileDataGridRow
    >,
  ) => JSX.Element,
) => ({
  getFormatter: getCurrentFormatter,
  headerRenderer: ProfileDataGridColumnHeader,
  key,
  name: getColumnNameBase(
    transmissionCode,
    transmissionName,
    physicalSegmentRefString,
    nameSuffix,
  ),
  resizable: true,
  summaryFormatter,
  width: DATA_GRID_DATA_COLUMN_WIDTH_VALUE,
});

const buildPorColumn = (
  key: string,
  isEditingUniqueProfiles: boolean,
  physical_segment_ref: number | null,
  transmissionCode: string | undefined,
  physicalSegmentRefString: string,
  getEditor?: (
    DataGridSelectionContext: Context<IDataGridSelectionContext>,
  ) => (
    props: EditorProps<TProfileDataGridRow, TProfileDataGridSummaryRow>,
  ) => JSX.Element,
  getCurrentFormatter?: (
    DataGridSelectionContext: Context<IDataGridSelectionContext>,
  ) => (
    props: FormatterProps<TProfileDataGridRow, TProfileDataGridSummaryRow>,
  ) => JSX.Element,
  summaryFormatter?: (
    props: SummaryFormatterProps<
      TProfileDataGridSummaryRow,
      TProfileDataGridRow
    >,
  ) => JSX.Element,
) => ({
  ...getBaseColumnAttributes(
    key,
    transmissionCode,
    'POR',
    physicalSegmentRefString,
    'POR-Current',
    getCurrentFormatter,
    summaryFormatter,
  ),
  editorOptions: isEditingUniqueProfiles ? EDITOR_OPTIONS : undefined,
  getEditor:
    physical_segment_ref === null || !isEditingUniqueProfiles
      ? undefined
      : getEditor,
});

const buildPodColumn = (
  key: string,
  isEditingUniqueProfiles: boolean,
  physical_segment_ref: number | null,
  transmissionCode: string | undefined,
  physicalSegmentRefString: string,
  getEditor?: (
    DataGridSelectionContext: Context<IDataGridSelectionContext>,
  ) => (
    props: EditorProps<TProfileDataGridRow, TProfileDataGridSummaryRow>,
  ) => JSX.Element,
  getCurrentFormatter?: (
    DataGridSelectionContext: Context<IDataGridSelectionContext>,
  ) => (
    props: FormatterProps<TProfileDataGridRow, TProfileDataGridSummaryRow>,
  ) => JSX.Element,
  summaryFormatter?: (
    props: SummaryFormatterProps<
      TProfileDataGridSummaryRow,
      TProfileDataGridRow
    >,
  ) => JSX.Element,
  showLosses?: boolean,
) => ({
  ...getBaseColumnAttributes(
    key,
    transmissionCode,
    'POD',
    physicalSegmentRefString,
    isEditingUniqueProfiles ? 'POD Profile' : 'POD-Current',
    getCurrentFormatter,
    summaryFormatter,
  ),
  editorOptions: isEditingUniqueProfiles ? EDITOR_OPTIONS : undefined,
  getEditor:
    showLosses || physical_segment_ref || isEditingUniqueProfiles
      ? getEditor
      : undefined,
});

const buildLossColumn = (
  key: string,
  transmissionCode: string | undefined,
  physicalSegmentRefString: string,
  getFormatter?: (
    DataGridSelectionContext: Context<IDataGridSelectionContext>,
  ) => (
    props: FormatterProps<TProfileDataGridRow, TProfileDataGridSummaryRow>,
  ) => JSX.Element,
  summaryFormatter?: (
    props: SummaryFormatterProps<
      TProfileDataGridSummaryRow,
      TProfileDataGridRow
    >,
  ) => JSX.Element,
) => ({
  ...getBaseColumnAttributes(
    key,
    transmissionCode,
    'LOSS',
    physicalSegmentRefString,
    'LOSS-Current',
    getFormatter,
    summaryFormatter,
  ),
});

const buildAllocationsColumn = (
  currentSegment: ISegment | undefined,
  physical_segment_ref: number | null,
  adjustedContractNumber: string,
  trans_alloc_id: number,
  transmissionName: string,
  isPrintView: boolean | undefined,
  productName: string,
  transmissionPhysicalSegment: IETagTransmissionPhysicalSegment | undefined,
  totalTransmissionAllocations: number,
  transmissionCode: string | undefined,
  getEditor?: (
    DataGridSelectionContext: Context<IDataGridSelectionContext>,
  ) => (
    props: EditorProps<TProfileDataGridRow, TProfileDataGridSummaryRow>,
  ) => JSX.Element,
  getFormatter?: (
    DataGridSelectionContext: Context<IDataGridSelectionContext>,
  ) => (
    props: FormatterProps<TProfileDataGridRow, TProfileDataGridSummaryRow>,
  ) => JSX.Element,
  summaryFormatter?: (
    props: SummaryFormatterProps<
      TProfileDataGridSummaryRow,
      TProfileDataGridRow
    >,
  ) => JSX.Element,
) => ({
  resizable: false,
  cellClass: `${
    physical_segment_ref === null ? CELL_DISABLED_CLASS : ''
  }`.trim(),
  colSpan: currentSegment
    ? (props: any) => getColSpan(props, currentSegment.count)
    : () => 1,
  editorOptions: EDITOR_OPTIONS,
  getEditor: physical_segment_ref === null ? undefined : getEditor,
  getFormatter,
  headerRenderer: ProfileDataGridColumnHeader,
  key: getKeyForTransAllocProfile({
    adjustedContractNumber,
    physicalSegmentRef: physical_segment_ref,
    transAllocId: trans_alloc_id,
    transmissionName,
  }),
  name: getSegmentColumnName(
    transmissionCode,
    adjustedContractNumber,
    currentSegment,
    productName,
    transmissionPhysicalSegment,
    isPrintView,
  ),
  summaryFormatter,
  width: totalTransmissionAllocations < 3 ? 80 : null,
});

const buildTransAllocTotalsColumn = (
  physical_segment_ref: number,
  physicalSegmentRefString: string,
  transmissionCode: string | undefined,
  getFormatter?: (
    DataGridSelectionContext: Context<IDataGridSelectionContext>,
  ) => (
    props: FormatterProps<TProfileDataGridRow, TProfileDataGridSummaryRow>,
  ) => JSX.Element,
  summaryFormatter?: (
    props: SummaryFormatterProps<
      TProfileDataGridSummaryRow,
      TProfileDataGridRow
    >,
  ) => JSX.Element,
) => ({
  cellClass: CELL_TOTALS_CLASS,
  getFormatter,
  headerRenderer: ProfileDataGridColumnHeader,
  key: getKeyForTransmissionEnergy({
    physicalSegmentRef: physical_segment_ref,
    porPodLossOrAllocTotals: 'ALLOC_TOTALS',
  }),
  name: getTransAllocTotalsColumnName(
    transmissionCode,
    'TOTALS',
    physicalSegmentRefString,
  ),
  resizable: true,
  summaryFormatter,
  width: DATA_GRID_DATA_COLUMN_WIDTH_VALUE,
});

const buildSegmentArray = (
  sortedTransmissionAllocations: IETagTransmissionAllocation[],
) => {
  // Get unique physical segments from allocations
  const uniquePhysicalSegmentRefs: (number | null)[] =
    sortedTransmissionAllocations
      .map((item) => item.physical_segment_ref)
      .filter((value, index, self) => self.indexOf(value) === index);
  const segmentArray: ISegment[] = [];
  uniquePhysicalSegmentRefs.forEach((segment) => {
    const count = sortedTransmissionAllocations.filter(
      (item) => item.physical_segment_ref === segment,
    ).length;

    const contracts = sortedTransmissionAllocations
      .filter((item) => item.physical_segment_ref === segment)
      .map((item) => {
        return {
          contract: item.contract_number,
          product_name: item.trans_product_ref?.product_name,
        };
      });

    segmentArray.push({
      segment,
      count,
      contracts,
    });
  });
  return segmentArray;
};

const getProductName = (
  eTagTransmissionAllocation: IETagTransmissionAllocation,
) =>
  eTagTransmissionAllocation.trans_product_ref?.product_name
    ? `\n${eTagTransmissionAllocation.trans_product_ref.product_name}`
    : '';

export const getTransmissionColumns = (
  transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[] | null,
  sortedTransmissionAllocations: IETagTransmissionAllocation[],
  selectedTableConfiguration: ETableConfiguration | undefined,
  isEditingUniqueProfiles: boolean,
  getCurrentFormatter?: (
    DataGridSelectionContext: Context<IDataGridSelectionContext>,
  ) => (
    props: FormatterProps<TProfileDataGridRow, TProfileDataGridSummaryRow>,
  ) => JSX.Element,
  getFormatter?: (
    DataGridSelectionContext: Context<IDataGridSelectionContext>,
  ) => (
    props: FormatterProps<TProfileDataGridRow, TProfileDataGridSummaryRow>,
  ) => JSX.Element,
  getEditor?: (
    DataGridSelectionContext: Context<IDataGridSelectionContext>,
  ) => (
    props: EditorProps<TProfileDataGridRow, TProfileDataGridSummaryRow>,
  ) => JSX.Element,
  summaryFormatter?: (
    props: SummaryFormatterProps<
      TProfileDataGridSummaryRow,
      TProfileDataGridRow
    >,
  ) => JSX.Element,
  showLosses?: boolean,
  isPrintView?: boolean,
  losses_v2_enabled?: boolean | null,
  // isPodRequired?: boolean,
): TProfileDataGridColumn[] => {
  if (transmissionPhysicalSegments === null) {
    return [];
  }

  // Map to store column configurations by key
  const transmissionColumnsMap: Record<string, TProfileDataGridColumn> = {};

  // Build array of segment information including contract counts
  const segmentArray: ISegment[] = buildSegmentArray(
    sortedTransmissionAllocations,
  );

  // Calculate total number of transmission allocations across all segments
  const numTransmissionAllocations = segmentArray
    .slice()
    .map((segment) => segment.count)
    .reduce((sum, count) => sum + count, 0);

  transmissionPhysicalSegments.forEach(
    (
      transmissionPhysicalSegment: IETagTransmissionPhysicalSegment,
      segIndex: number,
    ) => {
      const filteredTransmissionAllocations =
        sortedTransmissionAllocations.filter(
          (datum) =>
            datum.physical_segment_ref ===
            transmissionPhysicalSegment.physical_segment_id,
        );
      const transmissionProviderEntityCode =
        transmissionPhysicalSegment?.tp_code?.entity_code;

      const physical_segment_ref =
        transmissionPhysicalSegment.physical_segment_id;

      const porColumn = buildPorColumn(
        getKeyForTransmissionEnergy({
          physicalSegmentRef: physical_segment_ref,
          porPodLossOrAllocTotals: 'POR',
        }),
        isEditingUniqueProfiles,
        physical_segment_ref,
        transmissionProviderEntityCode,
        getPhysicalSegmentRefString(physical_segment_ref),
        getEditor,
        getCurrentFormatter,
        summaryFormatter,
      );
      const podColumn = buildPodColumn(
        getKeyForTransmissionEnergy({
          physicalSegmentRef: physical_segment_ref,
          porPodLossOrAllocTotals: 'POD',
        }),
        isEditingUniqueProfiles,
        physical_segment_ref,
        transmissionProviderEntityCode,
        getPhysicalSegmentRefString(physical_segment_ref),
        getEditor,
        getCurrentFormatter,
        summaryFormatter,
        showLosses,
      );

      const lossColumn = buildLossColumn(
        getKeyForTransmissionEnergy({
          physicalSegmentRef: physical_segment_ref,
          porPodLossOrAllocTotals: 'LOSS',
        }),
        transmissionPhysicalSegment.tp_code?.entity_code,
        getPhysicalSegmentRefString(physical_segment_ref),
        getFormatter,
        summaryFormatter,
      );

      const transAllocTotalsColumn = buildTransAllocTotalsColumn(
        physical_segment_ref,
        getPhysicalSegmentRefString(physical_segment_ref),
        transmissionProviderEntityCode,
        getFormatter,
        summaryFormatter,
      );
      // Process each transmission allocation to generate columns
      filteredTransmissionAllocations.forEach(
        (
          eTagTransmissionAllocation: IETagTransmissionAllocation,
          index: number,
        ) => {
          const adjustedContractNumber = getAdjustedContractNumber(
            eTagTransmissionAllocation,
          );
          const { physical_segment_ref, trans_alloc_id } =
            eTagTransmissionAllocation;

          // Get current segment info and extract allocation details
          const currentSegment = segmentArray.find(
            (segment) => segment.segment === physical_segment_ref,
          );

          // Create main segment column
          const allocationsColumn = buildAllocationsColumn(
            currentSegment,
            physical_segment_ref,
            adjustedContractNumber,
            trans_alloc_id,
            getTransmissionName(
              eTagTransmissionAllocation,
              transmissionPhysicalSegments,
            ),
            isPrintView,
            getProductName(eTagTransmissionAllocation),
            transmissionPhysicalSegment,
            numTransmissionAllocations,
            transmissionProviderEntityCode,
            getEditor,
            getFormatter,
            summaryFormatter,
          );

          // Add POR column if in All configuration
          if (selectedTableConfiguration === ETableConfiguration.All) {
            transmissionColumnsMap[porColumn.key] = porColumn;
          }

          transmissionColumnsMap[allocationsColumn.key] = allocationsColumn;

          // Add POD column based on configuration
          if (
            selectedTableConfiguration === ETableConfiguration.POD ||
            selectedTableConfiguration === ETableConfiguration.All ||
            isEditingUniqueProfiles ||
            showLosses
          ) {
            if (
              !showLosses ||
              segIndex !== transmissionPhysicalSegments.length - 1
            ) {
              transmissionColumnsMap[podColumn.key] = podColumn;
            }
          }

          // Add Loss column if configured
          if (
            (selectedTableConfiguration === ETableConfiguration.All ||
              selectedTableConfiguration ===
                ETableConfiguration.NewTagRequest) &&
            showLosses
          ) {
            transmissionColumnsMap[lossColumn.key] = lossColumn;
          }

          // Add totals column if needed
          if (
            selectedTableConfiguration === ETableConfiguration.ProfileTotals ||
            selectedTableConfiguration === ETableConfiguration.All
          ) {
            transmissionColumnsMap[transAllocTotalsColumn.key] =
              transAllocTotalsColumn;
          }

          // Handle grouped column headers tooltip, hide tooltip, render as html, and set tooltip text
          if (currentSegment && currentSegment.count > 1) {
            Object.values(transmissionColumnsMap).forEach(
              (column: TProfileDataGridColumn) => {
                const columnName = String(column.name);
                if (columnName.includes('<div')) {
                  (column as any)['hideTooltip'] = true;
                  (column as any)['renderTextAsHtml'] = true;
                  (column as any)['tooltipText'] = getTooltipText(
                    transmissionProviderEntityCode,
                    adjustedContractNumber,
                    currentSegment,
                    getProductName(eTagTransmissionAllocation),
                  );
                }
              },
            );
          }
        },
      );
    },
  );

  const sortedEntries = sortRecordByKey(transmissionColumnsMap);
  const columns = Object.values(sortedEntries);

  const sortedColumns = columns
    .map(mapColumnToSortingObjects)
    .sort(sortColumnsByPhysicalSegmentAndType)
    .map(
      (mappedColumn: ColumnSortKey) =>
        columns.find(
          (column) => column.key === mappedColumn.originalColumnKey,
        )!,
    );

  if (losses_v2_enabled && sortedColumns.length > 0) {
    const lastColumn = sortedColumns[sortedColumns.length - 1];
    if (lastColumn && typeof lastColumn.name === 'string') {
      const nameComponents = lastColumn.name.split('\n');
      const caption = nameComponents[nameComponents.length - 1];
      if (caption.includes('POD')) {
        nameComponents[nameComponents.length - 1] = 'POD/Load';
        lastColumn.name = nameComponents.join('\n');
      }
    }
  }

  const columnsWithSeparators =
    addSeparatorClassesToFirstColumnOfPhysicalSegment(sortedColumns);

  return columnsWithSeparators;
};

type columnType = 'POR' | 'ALLOC' | 'POD' | 'LOSS' | 'ALLOC_TOTALS';

const columnTypes = {
  POR: 1,
  ALLOC: 2,
  POD: 3,
  LOSS: 4,
  ALLOC_TOTALS: 5,
};

const sortStringsByColumnType = (a: columnType, b: columnType) => {
  return columnTypes[a] - columnTypes[b];
};

interface ColumnSortKey {
  physicalSegmentRef: number;
  type: columnType;
  transAllocId: number | null;
  originalColumnKey?: string;
}

const sortColumnsByPhysicalSegmentAndType = (
  objA: ColumnSortKey,
  objB: ColumnSortKey,
) => {
  if (objA.physicalSegmentRef !== objB.physicalSegmentRef) {
    return objA.physicalSegmentRef - objB.physicalSegmentRef;
  }

  if (columnTypes[objA.type] !== columnTypes[objB.type]) {
    return sortStringsByColumnType(objA.type, objB.type);
  }

  const transAllocIdA = objA.transAllocId ?? 0;
  const transAllocIdB = objB.transAllocId ?? 0;

  return transAllocIdA - transAllocIdB;
};

/**
  Examples of column keys:
  "ps2-ALLOC_TOTALS",
  "ps2-LOSS",
  "ps2-POD",
  "ps2-POR",
  "ps3-ALLOC_TOTALS",
  "ps3-LOSS",
  "ps3-POD",
  "ps3-POR",
  "ps4-ALLOC_TOTALS",
  "ps4-LOSS",
  "ps4-POD",
  "ps4-POR",
  "ta1:ps2:TVA:101640906",
  "ta2:ps3:PJM:104742493",
  "ta4:ps4:PJM:5430858"
  */
const mapColumnToSortingObjects = (
  column: TProfileDataGridColumn,
): ColumnSortKey => {
  const physicalSegmentRefString = /ps(\d+)/.exec(column.key)?.[1];
  const physicalSegmentRef = physicalSegmentRefString
    ? parseInt(physicalSegmentRefString, 10)
    : null;

  if (physicalSegmentRef === null) {
    throw new Error(
      `Physical segment ref is null for column key: ${column.key}`,
    );
  }

  const transAllocIdString = /ta(\d+):/.exec(column.key)?.[1];
  const transAllocId = transAllocIdString ? parseInt(transAllocIdString) : null;

  const columnType = /ALLOC_TOTALS|LOSS|POD|POR/.exec(
    column.key,
  )?.[0] as columnType | null;

  if (transAllocId === null && columnType === null) {
    throw new Error(
      `TransAllocId and columnType are null for column key: ${column.key}`,
    );
  }

  return {
    physicalSegmentRef,
    transAllocId: transAllocId,
    type: columnType ?? 'ALLOC',
    originalColumnKey: column.key,
  };
};

const addSeparatorClassesToFirstColumnOfPhysicalSegment = (
  columns: TProfileDataGridColumn[],
) => {
  let currentPhysicalSegment = -1;
  const columnsWithSeparators = columns.map((column, index) => {
    const mappedObject = mapColumnToSortingObjects(column);
    const physical_segment_ref = mappedObject.physicalSegmentRef;

    const newColumn = { ...column };
    if (physical_segment_ref !== currentPhysicalSegment) {
      currentPhysicalSegment = physical_segment_ref;
      newColumn.cellClass = `${
        column.cellClass ?? ''
      } ${LEFT_SEPARATOR_CLASS}`.trim();
      newColumn.headerCellClass = `${
        column.headerCellClass ?? ''
      } ${LEFT_SEPARATOR_CLASS}`.trim();
      newColumn.summaryCellClass = `${
        column.summaryCellClass ?? ''
      } ${LEFT_SEPARATOR_CLASS}`.trim();
    }

    if (index === columns.length - 1) {
      newColumn.cellClass = `${
        newColumn.cellClass ?? ''
      } ${RIGHT_SEPARATOR_CLASS}`.trim();
      newColumn.headerCellClass = `${
        newColumn.headerCellClass ?? ''
      } ${RIGHT_SEPARATOR_CLASS}`.trim();
      newColumn.summaryCellClass = `${
        newColumn.summaryCellClass ?? ''
      } ${RIGHT_SEPARATOR_CLASS}`.trim();
    }

    return newColumn;
  });

  return columnsWithSeparators;
};
