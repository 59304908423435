import { AxiosResponse } from 'axios';
import Separator from 'components/atoms/Separator/Separator';
import DetailView from 'components/molecules/DetailView/DetailView';
import {
  applyProfileChangeToDetail,
  validateProfileInformation,
} from 'components/organisms/ProfileInformationView/helpers';
import ProfileChangeManager from 'components/organisms/ProfileInformationView/ProfileChangeManager/ProfileChangeManager';
import ProfileInformationManager from 'components/organisms/ProfileInformationView/ProfileInformationManager/ProfileInformationManager';
import { PROFILE_CHANGE_ID_QUERY_PARAM } from 'constants/Detail';
import { NOT_FOUND_STATUS_CODE } from 'constants/misc';
import {
  COLUMN_LAYOUT_SHARED_STYLES,
  ROW_LAYOUT_SHARED_STYLES,
  STANDARD_SPACING_VALUE,
} from 'constants/styles';
import { DATE_TIME_FORMAT } from 'constants/time';
import { EDistributedTagItem } from 'enums/ETag';
import { ERetreiveState } from 'enums/General';
import { EViewMode, EViewResize } from 'enums/View';
import { IOption } from 'interfaces/Component';
import {
  IETagPhysicalSegmentsProfile,
  IETagProfileChangeRequest,
  IETagProfileChangeRequestResponse,
  IETagTransmissionAllocation,
  IETagTransmissionAllocationProfile,
  IETagTransmissionSegment,
} from 'interfaces/ETag';
import { IViewProps } from 'interfaces/View';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { useDispatch, useSelector } from 'react-redux';
import {
  detailSetValidations,
  detailSetEditedPhysicalSegmentColumn,
} from 'reduxes/Detail/actions';
import { retrieveETagDistributedProfileChange } from 'services/agent/tags/distributed';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TETagEditedPhysicalSegmentColumn } from 'types/ETag';
import { TRootState } from 'types/Redux';
import {
  getGenerationPhysicalSegmentName,
  getLoadPhysicalSegmentName,
  transmissionAllocationSorter,
} from 'utils/detail';
import { NotFoundError, captureError } from 'utils/error';
import { encodeIds } from 'utils/general';
import { getDetailToEntityUserSelectedTimeZone } from 'utils/user';
import { ZonedDateTime } from 'utils/zonedDateTime';
import useAsyncEffect from 'use-async-effect';
import { useLocation } from 'react-router-dom';

interface IViewLayoutProps {
  isViewFull: boolean;
}

const FULLSCREEN_STYLES = `
${ROW_LAYOUT_SHARED_STYLES}

height: 100%;

> :first-child {
 flex: 1;
}

> :not(:last-child) {
 margin-right: ${2 * STANDARD_SPACING_VALUE}px;
}

> :last-child {
 flex: 2;
}
`;

const STANDARD_STYLES = `
${COLUMN_LAYOUT_SHARED_STYLES}
height: 100%;

> :first-child {
  flex: 1;
}

> :not(:last-child) {
  margin-bottom: ${2 * STANDARD_SPACING_VALUE}px;
}

> :last-child {
  flex: 2;
}
`;

const Layout = styled.div<IViewLayoutProps>`
  ${(props) => (props.isViewFull ? FULLSCREEN_STYLES : STANDARD_STYLES)}
`;

const retrieveProfileInformationViewState = (state: TRootState) => {
  const {
    allTransmissionAllocations,
    allUnfilteredTransmissionAllocations,
    generationPhysicalSegment,
    loadPhysicalSegment,
    pageMode,
    physicalSegmentsProfiles,
    retrievingDetail,
    retrievingDistributedTagItems,
    retrievingProfiles,
    retrievingSnapshots,
    selectedRequestKey,
    toEntity,
    transmission_physical_segments,
    transmissionAllocations,
    viewMode,
    editedPhysicalSegmentsProfileType,
  } = state.detail.present;
  const isDetailLoading: boolean =
    (retrievingDetail !== ERetreiveState.NotRetrieving &&
      retrievingDetail !== ERetreiveState.RetrievingCompleted) ||
    (retrievingDistributedTagItems[EDistributedTagItem.TransmissionAllocations]
      .retrieveState !== ERetreiveState.NotRetrieving &&
      retrievingDistributedTagItems[EDistributedTagItem.TransmissionAllocations]
        .retrieveState !== ERetreiveState.RetrievingCompleted) ||
    (retrievingDistributedTagItems[EDistributedTagItem.LossAccountings]
      .retrieveState !== ERetreiveState.NotRetrieving &&
      retrievingDistributedTagItems[EDistributedTagItem.LossAccountings]
        .retrieveState !== ERetreiveState.RetrievingCompleted) ||
    (retrievingDistributedTagItems[EDistributedTagItem.MarketSegment]
      .retrieveState !== ERetreiveState.NotRetrieving &&
      retrievingDistributedTagItems[EDistributedTagItem.MarketSegment]
        .retrieveState !== ERetreiveState.RetrievingCompleted) ||
    (retrievingDistributedTagItems[EDistributedTagItem.PhysicalSegment]
      .retrieveState !== ERetreiveState.NotRetrieving &&
      retrievingDistributedTagItems[EDistributedTagItem.PhysicalSegment]
        .retrieveState !== ERetreiveState.RetrievingCompleted) ||
    (retrievingDistributedTagItems[EDistributedTagItem.PhysicalSegmentsProfiles]
      .retrieveState !== ERetreiveState.NotRetrieving &&
      retrievingDistributedTagItems[
        EDistributedTagItem.PhysicalSegmentsProfiles
      ].retrieveState !== ERetreiveState.RetrievingCompleted);
  const isLoadingProfiles: boolean =
    retrievingProfiles !== ERetreiveState.NotRetrieving &&
    retrievingProfiles !== ERetreiveState.RetrievingCompleted;
  const isLoadingSnapshots: boolean =
    retrievingSnapshots !== ERetreiveState.NotRetrieving &&
    retrievingSnapshots !== ERetreiveState.RetrievingCompleted;
  const timeZone: TTimeZone = getDetailToEntityUserSelectedTimeZone(state);

  return {
    allTransmissionAllocations,
    allUnfilteredTransmissionAllocations,
    generationPhysicalSegment,
    isDetailLoading,
    isLoadingProfiles,
    isLoadingSnapshots,
    loadPhysicalSegment,
    pageMode,
    physicalSegmentsProfiles,
    selectedRequestKey,
    timeZone,
    toEntity,
    transmission_physical_segments,
    transmissionAllocations,
    viewMode,
    editedPhysicalSegmentsProfileType,
  };
};

const ProfileInformationView = (props: IViewProps): JSX.Element => {
  const dispatch = useDispatch();
  const { currentTheme } = useThemeSwitcher();
  const {
    allTransmissionAllocations,
    allUnfilteredTransmissionAllocations,
    generationPhysicalSegment,
    isDetailLoading,
    isLoadingProfiles,
    isLoadingSnapshots,
    loadPhysicalSegment,
    pageMode,
    physicalSegmentsProfiles,
    selectedRequestKey,
    timeZone,
    toEntity,
    transmission_physical_segments,
    transmissionAllocations,
    viewMode,
    editedPhysicalSegmentsProfileType,
  } = useSelector(retrieveProfileInformationViewState);
  const { layoutGrid, encodedPermissionsId, resize, viewId } = props;
  const isLoading: boolean =
    isDetailLoading || isLoadingProfiles || isLoadingSnapshots;
  const [isViewFull, setIsViewFull] = useState<boolean>(false);
  const [
    hasInformationDataGridOverflowedX,
    setHasInformationDataGridOverflowedX,
  ] = useState<boolean>(false);
  const [
    hasInformationDataGridOverflowedY,
    setHasInformationDataGridOverflowedY,
  ] = useState<boolean>(false);
  const [hasChangeDataGridOverflowedX, setHasChangeDataGridOverflowedX] =
    useState<boolean>(false);
  const [hasChangeDataGridOverflowedY, setHasChangeDataGridOverflowedY] =
    useState<boolean>(false);
  const [startDateTime, setStartDateTime] = useState<string | null>(null);
  const [stopDateTime, setStopDateTime] = useState<string | null>(null);
  const [profileDateOptions, setProfileDateOptions] = useState<
    IOption<ZonedDateTime>[]
  >([]);
  const [selectedProfileChangeDate, setSelectedProfileChangeDate] = useState<
    ZonedDateTime | undefined
  >(undefined);
  const { search } = useLocation();

  const addedTransmissionAllocations: IETagTransmissionAllocation[] =
    useMemo(() => {
      const transAllocs: IETagTransmissionAllocation[] = [];
      transmissionAllocations?.forEach(
        (transmissionAllocation: IETagTransmissionAllocation) => {
          if (
            allTransmissionAllocations?.find(
              (transAllocFind: IETagTransmissionAllocation) =>
                transAllocFind.trans_alloc_id ===
                transmissionAllocation.trans_alloc_id,
            ) === undefined
          ) {
            transAllocs.push(transmissionAllocation);
          }
        },
      );
      return transAllocs;
    }, [allTransmissionAllocations, transmissionAllocations]);

  const profileTransmissionAllocations = useMemo(() => {
    const transAllocProfiles: IETagTransmissionAllocationProfile[] = [];
    physicalSegmentsProfiles?.forEach(
      (profile: IETagPhysicalSegmentsProfile) => {
        const segments: IETagTransmissionSegment[] | null | undefined =
          profile.physical_segments_profiles?.transmission
            ?.transmission_segments;
        segments?.forEach((segment: IETagTransmissionSegment) => {
          segment.trans_alloc_profiles?.forEach(
            (transAllocProfile: IETagTransmissionAllocationProfile) => {
              if (
                transAllocProfiles.find(
                  (transAlloc: IETagTransmissionAllocationProfile) =>
                    transAlloc.trans_alloc_id ===
                    transAllocProfile.trans_alloc_id,
                ) === undefined
              ) {
                transAllocProfiles.push(transAllocProfile);
              }
            },
          );
        });
      },
    );
    const transAllocs: IETagTransmissionAllocation[] = [];
    allUnfilteredTransmissionAllocations?.forEach(
      (transAlloc: IETagTransmissionAllocation) => {
        if (
          transAllocProfiles.find(
            (transAllocProfile: IETagTransmissionAllocationProfile) =>
              transAllocProfile.trans_alloc_id === transAlloc.trans_alloc_id,
          )
        ) {
          transAllocs.push(transAlloc);
        }
      },
    );
    if (selectedRequestKey === 'C' || selectedRequestKey === 'CP') {
      transAllocs.push(...addedTransmissionAllocations);
    }
    return transAllocs;
  }, [
    addedTransmissionAllocations,
    allUnfilteredTransmissionAllocations,
    physicalSegmentsProfiles,
    selectedRequestKey,
  ]);

  const handleResize = useCallback(
    (viewId: string, viewResize: EViewResize) => {
      resize(viewId, viewResize);

      setIsViewFull(viewId === props.viewId && viewResize === EViewResize.Full);
    },
    [props.viewId, resize],
  );

  const generationPhysicalSegmentName: string = useMemo(
    () => getGenerationPhysicalSegmentName(generationPhysicalSegment),
    [generationPhysicalSegment],
  );

  const loadPhysicalSegmentName: string = useMemo(
    () => getLoadPhysicalSegmentName(loadPhysicalSegment),
    [loadPhysicalSegment],
  );

  const sortedProfileDataTransmissionAllocations: IETagTransmissionAllocation[] =
    useMemo(() => {
      let uniqueProfileTransmissionAllocations = [
        ...profileTransmissionAllocations,
      ].sort(transmissionAllocationSorter);
      uniqueProfileTransmissionAllocations =
        uniqueProfileTransmissionAllocations.filter(
          (value, index, self) =>
            index ===
            self.findIndex(
              (transAlloc) =>
                transAlloc.contract_number === value.contract_number &&
                transAlloc.trans_alloc_id === value.trans_alloc_id &&
                transAlloc.physical_segment_ref === value.physical_segment_ref,
            ),
        );
      return uniqueProfileTransmissionAllocations;
    }, [profileTransmissionAllocations]);

  const sortedTransmissionAllocations: IETagTransmissionAllocation[] = useMemo(
    () =>
      transmissionAllocations === null
        ? []
        : [...transmissionAllocations].sort(transmissionAllocationSorter),
    [transmissionAllocations],
  );

  const title: string = useMemo(() => {
    let title: string = 'Profile Data';

    if (viewMode !== EViewMode.None) {
      title += ` for Date Time Range: [${
        isLoading || startDateTime === null
          ? ''
          : ZonedDateTime.parseIso(startDateTime, timeZone).format(
              DATE_TIME_FORMAT,
            )
      } - ${
        isLoading || stopDateTime === null
          ? ''
          : ZonedDateTime.parseIso(stopDateTime, timeZone).format(
              DATE_TIME_FORMAT,
            )
      }]`;
    }

    return title;
  }, [isLoading, startDateTime, stopDateTime, timeZone, viewMode]);

  const { detailValidations, validationMessages } = useMemo(() => {
    if (isLoading) {
      return {
        detailValidations: undefined,
        validationMessages: undefined,
      };
    }

    return validateProfileInformation(physicalSegmentsProfiles);
  }, [isLoading, physicalSegmentsProfiles]);

  useEffect(() => {
    if (detailValidations !== undefined) {
      dispatch(detailSetValidations({ detailValidations }));
    }
  }, [detailValidations, dispatch]);

  useAsyncEffect(async () => {
    if (!isLoading) {
      // If at least starting in edit mode, and not a draft or template, check for a profileChangeId
      // and if found kick off the logic to apply it to the tag
      if (
        viewMode === EViewMode.EditETagAdjustment ||
        viewMode === EViewMode.EditETagAdjustmentWithATF ||
        viewMode === EViewMode.EditETagCorrection
      ) {
        const query: URLSearchParams = new URLSearchParams(search);
        const incomingProfileChangeId: string | null = query.get(
          PROFILE_CHANGE_ID_QUERY_PARAM,
        );
        if (incomingProfileChangeId && toEntity) {
          // As of 11/12/24 we only take into account the trans alloc and energy profiles, disregarding the curtailment and
          // loss accounting info we may recieve from the profileChanges API call. This info may be added at a later date, but is not
          // currently needed for our workflow.
          try {
            const retrieveProfileChangeResponse: AxiosResponse<IETagProfileChangeRequestResponse> =
              await retrieveETagDistributedProfileChange(
                toEntity.to_entity,
                incomingProfileChangeId,
              );
            if (
              retrieveProfileChangeResponse.status === NOT_FOUND_STATUS_CODE
            ) {
              throw new NotFoundError(
                `No profile change found with ID: ${incomingProfileChangeId}`,
              );
            }

            const profileChangeResponse: IETagProfileChangeRequest =
              retrieveProfileChangeResponse.data.response;

            await applyProfileChangeToDetail(profileChangeResponse, dispatch);
          } catch (error: any) {
            captureError(
              error,
              `Error loading profile change with ID: ${incomingProfileChangeId}`,
            );
          }
        }
      }
    }
  }, [dispatch, isLoading]);

  const setEditedProfileType = useCallback(
    (editedPhysicalSegmentsProfileType: TETagEditedPhysicalSegmentColumn) => {
      dispatch(
        detailSetEditedPhysicalSegmentColumn({
          editedPhysicalSegmentsProfileType,
        }),
      );
    },
    [dispatch],
  );

  return (
    <DetailView
      alwaysOfferResize={true}
      className='profile-information-view'
      isLoading={isDetailLoading}
      isOverflowedX={
        hasInformationDataGridOverflowedX || hasChangeDataGridOverflowedX
      }
      isOverflowedY={
        hasInformationDataGridOverflowedY || hasChangeDataGridOverflowedY
      }
      layoutGrid={layoutGrid}
      resize={handleResize}
      title={title}
      validationMessages={validationMessages}
      viewId={viewId}
      viewResizeSetting={EViewResize.Full}
    >
      <Layout isViewFull={isViewFull}>
        {viewMode === EViewMode.None ? null : (
          <ProfileInformationManager
            encodedPermissionsId={encodeIds([
              encodedPermissionsId,
              'profileInformationManager',
            ])}
            generationPhysicalSegmentName={generationPhysicalSegmentName}
            isDetailLoading={isDetailLoading}
            isLoadingProfiles={isLoadingProfiles}
            isLoadingSnapshots={isLoadingSnapshots}
            isViewFull={isViewFull}
            loadPhysicalSegment={loadPhysicalSegment}
            loadPhysicalSegmentName={loadPhysicalSegmentName}
            pageMode={pageMode}
            physicalSegmentsProfiles={physicalSegmentsProfiles}
            selectedProfileChangeDate={selectedProfileChangeDate}
            setHasDataGridOverflowedX={setHasInformationDataGridOverflowedX}
            setHasDataGridOverflowedY={setHasInformationDataGridOverflowedY}
            setProfileDateOptions={setProfileDateOptions}
            setStartDateTime={setStartDateTime}
            setStopDateTime={setStopDateTime}
            sortedTransmissionAllocations={
              sortedProfileDataTransmissionAllocations
            }
            toEntityId={toEntity?.to_entity!}
            transmissionPhysicalSegments={transmission_physical_segments}
            viewMode={viewMode}
            editedProfileType={editedPhysicalSegmentsProfileType}
            setEditedProfileType={setEditedProfileType}
          />
        )}
        {viewMode === EViewMode.EditETagAdjustment ||
        viewMode === EViewMode.EditETagAdjustmentWithATF ? (
          <>
            <Separator currentTheme={currentTheme!} />
            <ProfileChangeManager
              encodedPermissionsId={encodeIds([
                encodedPermissionsId,
                'profileChangeManager',
              ])}
              generationPhysicalSegmentName={generationPhysicalSegmentName}
              isViewFull={isViewFull}
              loadPhysicalSegment={loadPhysicalSegment}
              loadPhysicalSegmentName={loadPhysicalSegmentName}
              profileDateOptions={profileDateOptions}
              setHasDataGridOverflowedX={setHasChangeDataGridOverflowedX}
              setHasDataGridOverflowedY={setHasChangeDataGridOverflowedY}
              handleSelectedProfileChangeDateChange={
                setSelectedProfileChangeDate
              }
              sortedTransmissionAllocations={sortedTransmissionAllocations}
              timeZone={timeZone}
              toEntity={toEntity}
              transmissionPhysicalSegments={transmission_physical_segments}
              viewMode={viewMode}
            />
          </>
        ) : null}
      </Layout>
    </DetailView>
  );
};

export default ProfileInformationView;
