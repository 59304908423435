import { API } from 'aws-amplify';
import { AxiosResponse } from 'axios';
import { IETagLossPercentagesResponse } from 'interfaces/ETag';
import { AGENT_SERVICE_API_NAME, DEFAULT_OPTIONS } from 'services/constants';
import { TETagDraftId, TETagTagPrimaryKey } from 'types/ETag';
import { TToEntityId } from 'types/ToEntity';
import { LOSS_PERCENTAGES_ROUTE } from './constants';

export const retrieveETagLossPercentages = async (
  toEntityId: TToEntityId,
  draftId: TETagDraftId,
  tagPrimaryKey: TETagTagPrimaryKey | undefined,
): Promise<AxiosResponse<IETagLossPercentagesResponse>> =>
  await API.get(
    AGENT_SERVICE_API_NAME,
    LOSS_PERCENTAGES_ROUTE(toEntityId, draftId, tagPrimaryKey),
    DEFAULT_OPTIONS,
  );
