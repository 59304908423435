import LossMethodsReview from 'components/organisms/PhysicalPathReview/LossMethodsReview';
import OasisInfoReview from 'components/organisms/PhysicalPathReview/OasisInfoReview';
import {
  VIEW_DATA_TABLE_COLUMN_ID_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_LOSS_METHODS_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_LOSS_PERCENTAGES_WIDTH,
  VIEW_DATA_TABLE_COLUMN_MISC_INFO_WIDTH,
  VIEW_DATA_TABLE_COLUMN_OASIS_INFO_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_POINT_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_SE_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_TEXT_WIDTH,
} from 'constants/styles';
import { EViewMode } from 'enums/View';
import { getColumnTransmissionPhysicalSegmentSchedulingEntitiesRender } from 'hooks/useTransmissionEditColumns/useTransmissionEditColumns';
import {
  IETagTransmissionAllocation,
  IETagTransmissionPhysicalSegment,
} from 'interfaces/ETag';
import { IViewDataTableColumn } from 'interfaces/View';
import {
  IDetailGenerationPhysicalSegment,
  IDetailLoadPhysicalSegment,
  IDetailPhysicalSegment,
} from 'reduxes/Detail/types';
import {
  emptyRender,
  getColumnContactInfoRender,
  getColumnContractsRender,
  getColumnEntityInfoRender,
  getColumnMiscInfosRender,
  getColumnPointInfoRender,
  getColumnRender,
  getOASISInfoColumnRender,
  getPhysicalPathContactInfoTitle,
} from 'utils/views';
import { TViewDataTableColumnRender } from '../../../types/View';

interface IColumnProps {
  dataIndex: string;
  render: (...props: any) => JSX.Element;
  title: string | JSX.Element;
  width: string;
}
const getPhysicalPathMiscInfoTitle = (record: IDetailPhysicalSegment): string =>
  `Physical Path ${record.physical_segment_id} Misc Info`;

const getTransmissionMiscInfoTitle = (
  record: IETagTransmissionPhysicalSegment,
): string => `Physical Path ${record.physical_segment_id} Misc Info`;

const joinStrings = (value: string[]): string => value.join(', ');

export const generateGenerationReviewColumns = (
  isUnconstrained: boolean,
  handleExpandMiscInfos?: (record: IDetailGenerationPhysicalSegment) => void,
): IViewDataTableColumn<IDetailGenerationPhysicalSegment>[] => {
  const generationReviewColumns: IViewDataTableColumn<IDetailGenerationPhysicalSegment>[] =
    [
      {
        dataIndex: 'physical_segment_id',
        render: getColumnRender(isUnconstrained),
        title: 'PID',
        width: VIEW_DATA_TABLE_COLUMN_ID_COLUMN_WIDTH,
      },
      {
        dataIndex: 'market_segment_id',
        render: getColumnRender(isUnconstrained),
        title: 'MID',
        width: VIEW_DATA_TABLE_COLUMN_ID_COLUMN_WIDTH,
      },
      {
        dataIndex: 'gca',
        render: getColumnEntityInfoRender(isUnconstrained),
        title: 'GCA',
        width: VIEW_DATA_TABLE_COLUMN_TEXT_WIDTH,
      },
      {
        dataIndex: 'mo_code',
        render: getColumnEntityInfoRender(isUnconstrained),
        title: 'MO',
        width: VIEW_DATA_TABLE_COLUMN_TEXT_WIDTH,
      },
      {
        dataIndex: 'pse',
        render: getColumnEntityInfoRender(isUnconstrained),
        title: 'GPE',
        width: VIEW_DATA_TABLE_COLUMN_TEXT_WIDTH,
      },
      {
        dataIndex: 'generation_source',
        render: getColumnPointInfoRender(isUnconstrained),
        title: 'Source',
        width: VIEW_DATA_TABLE_COLUMN_POINT_COLUMN_WIDTH,
      },
    ];

  if (!isUnconstrained) {
    generationReviewColumns.push(
      {
        dataIndex: '',
        render: emptyRender,
        title: '',
        width: VIEW_DATA_TABLE_COLUMN_POINT_COLUMN_WIDTH,
      },
      {
        dataIndex: '',
        render: emptyRender,
        title: '',
        width: VIEW_DATA_TABLE_COLUMN_SE_COLUMN_WIDTH,
      },
    );
  }

  generationReviewColumns.push(
    {
      dataIndex: 'contracts',
      render: getColumnContractsRender(isUnconstrained),
      title: 'Contracts',
      width: VIEW_DATA_TABLE_COLUMN_OASIS_INFO_COLUMN_WIDTH,
    },
    {
      dataIndex: 'contactInfos',
      render: getColumnContactInfoRender(
        isUnconstrained,
        undefined,
        getPhysicalPathContactInfoTitle,
      ),
      title: 'Contact',
      width: isUnconstrained
        ? '33%'
        : VIEW_DATA_TABLE_COLUMN_LOSS_METHODS_COLUMN_WIDTH,
    },
    {
      dataIndex: 'misc_infos',
      render: getColumnMiscInfosRender(
        isUnconstrained,
        getPhysicalPathMiscInfoTitle,
        handleExpandMiscInfos,
      ),
      title: 'Misc Info',
      width: VIEW_DATA_TABLE_COLUMN_MISC_INFO_WIDTH,
    },
  );

  return generationReviewColumns;
};

export const generateTransmissionReviewColumns = (
  isLossMethodsPopover: boolean,
  isOasisInfoPopover: boolean,
  isUnconstrained: boolean,
  transmissionAllocations: IETagTransmissionAllocation[] | null,
  viewMode: EViewMode,
  handleExpandMiscInfos?: (record: IETagTransmissionPhysicalSegment) => void,
  showLosses?: boolean,
  showLossesV2?: boolean,
): IViewDataTableColumn<IETagTransmissionPhysicalSegment>[] => {
  const columns: IColumnProps[] = [
    {
      dataIndex: 'physical_segment_id',
      render: getColumnRender(isUnconstrained),
      title: 'PID',
      width: VIEW_DATA_TABLE_COLUMN_ID_COLUMN_WIDTH,
    },
    {
      dataIndex: 'market_segment_id',
      render: getColumnRender(isUnconstrained),
      title: 'MID',
      width: VIEW_DATA_TABLE_COLUMN_ID_COLUMN_WIDTH,
    },
    {
      dataIndex: 'tp_code',
      render: getColumnEntityInfoRender(isUnconstrained),
      title: 'TP',
      width: VIEW_DATA_TABLE_COLUMN_TEXT_WIDTH,
    },
    {
      dataIndex: 'mo_code',
      render: getColumnEntityInfoRender(isUnconstrained),
      title: 'MO',
      width: VIEW_DATA_TABLE_COLUMN_TEXT_WIDTH,
    },
    {
      dataIndex: 'pse',
      render: getColumnEntityInfoRender(isUnconstrained),
      title: 'PSE',
      width: VIEW_DATA_TABLE_COLUMN_TEXT_WIDTH,
    },
    {
      dataIndex: 'por',
      render: getColumnPointInfoRender(isUnconstrained),
      title: 'POR',
      width: VIEW_DATA_TABLE_COLUMN_POINT_COLUMN_WIDTH,
    },
    {
      dataIndex: 'pod',
      render: getColumnPointInfoRender(isUnconstrained),
      title: 'POD',
      width: VIEW_DATA_TABLE_COLUMN_POINT_COLUMN_WIDTH,
    },
    {
      dataIndex: 'scheduling_entities',
      render:
        getColumnTransmissionPhysicalSegmentSchedulingEntitiesRender(
          isUnconstrained,
        ),
      title: 'SE(s)',
      width: VIEW_DATA_TABLE_COLUMN_SE_COLUMN_WIDTH,
    },
    {
      dataIndex: 'oasis_info',
      render: getOASISInfoColumnRender(
        isUnconstrained,
        transmissionAllocations,
      ) as TViewDataTableColumnRender<IETagTransmissionPhysicalSegment>,
      title: <OasisInfoReview allowPopover={isOasisInfoPopover} />,
      width: VIEW_DATA_TABLE_COLUMN_OASIS_INFO_COLUMN_WIDTH,
    },
  ];

  if (showLosses || showLossesV2) {
    columns.push({
      dataIndex: 'loss_methods',
      render: getColumnRender(isUnconstrained, joinStrings),
      title: <LossMethodsReview allowPopover={isLossMethodsPopover} />,
      width: VIEW_DATA_TABLE_COLUMN_LOSS_METHODS_COLUMN_WIDTH,
    });
  }

  columns.push({
    dataIndex: 'misc_infos',
    render: getColumnMiscInfosRender(
      isUnconstrained,
      getTransmissionMiscInfoTitle,
      handleExpandMiscInfos,
    ),
    title: 'Misc Info',
    width: VIEW_DATA_TABLE_COLUMN_MISC_INFO_WIDTH,
  });
  if (
    (showLosses || showLossesV2) &&
    viewMode !== EViewMode.ReviewETagTemplate &&
    viewMode !== EViewMode.EditETagTemplate
  ) {
    const showLossesColumn = {
      dataIndex: `physical_segment_loss_percentage${showLossesV2 ? '_v2' : ''}`,
      render: getColumnRender(isUnconstrained),
      title: 'Losses %',
      width: VIEW_DATA_TABLE_COLUMN_LOSS_PERCENTAGES_WIDTH,
    };
    const lossesColumnIndex = columns.findIndex(
      (column) => column.dataIndex === 'loss_methods',
    );
    columns.splice(lossesColumnIndex + 1, 0, showLossesColumn);
  }

  return columns;
};

export const generateLoadReviewColumns = (
  isUnconstrained: boolean,
  handleExpandMiscInfos?: (record: IDetailLoadPhysicalSegment) => void,
): IViewDataTableColumn<IDetailLoadPhysicalSegment>[] => {
  const loadReviewColumns: IViewDataTableColumn<IDetailLoadPhysicalSegment>[] =
    [
      {
        dataIndex: 'physical_segment_id',
        render: getColumnRender(isUnconstrained),
        title: 'PID',
        width: VIEW_DATA_TABLE_COLUMN_ID_COLUMN_WIDTH,
      },
      {
        dataIndex: 'market_segment_id',
        render: getColumnRender(isUnconstrained),
        title: 'MID',
        width: VIEW_DATA_TABLE_COLUMN_ID_COLUMN_WIDTH,
      },
      {
        dataIndex: 'lca',
        render: getColumnEntityInfoRender(isUnconstrained),
        title: 'LCA',
        width: VIEW_DATA_TABLE_COLUMN_TEXT_WIDTH,
      },
      {
        dataIndex: 'mo_code',
        render: getColumnEntityInfoRender(isUnconstrained),
        title: 'MO',
        width: VIEW_DATA_TABLE_COLUMN_TEXT_WIDTH,
      },
      {
        dataIndex: 'pse',
        render: getColumnEntityInfoRender(isUnconstrained),
        title: 'LSE',
        width: VIEW_DATA_TABLE_COLUMN_TEXT_WIDTH,
      },
      {
        dataIndex: 'load_sink',
        render: getColumnPointInfoRender(isUnconstrained),
        title: 'Sink',
        width: VIEW_DATA_TABLE_COLUMN_POINT_COLUMN_WIDTH,
      },
    ];

  if (!isUnconstrained) {
    loadReviewColumns.push(
      {
        dataIndex: '',
        render: emptyRender,
        title: '',
        width: VIEW_DATA_TABLE_COLUMN_POINT_COLUMN_WIDTH,
      },
      {
        dataIndex: '',
        render: emptyRender,
        title: '',
        width: VIEW_DATA_TABLE_COLUMN_SE_COLUMN_WIDTH,
      },
    );
  }

  loadReviewColumns.push(
    {
      dataIndex: 'contracts',
      render: getColumnContractsRender(isUnconstrained),
      title: 'Contracts',
      width: VIEW_DATA_TABLE_COLUMN_OASIS_INFO_COLUMN_WIDTH,
    },
    {
      dataIndex: 'contactInfos',
      render: getColumnContactInfoRender(
        isUnconstrained,
        undefined,
        getPhysicalPathContactInfoTitle,
      ),
      title: 'Contact',
      width: isUnconstrained
        ? '33%'
        : VIEW_DATA_TABLE_COLUMN_LOSS_METHODS_COLUMN_WIDTH,
    },
    {
      dataIndex: 'misc_infos',
      render: getColumnMiscInfosRender(
        isUnconstrained,
        getPhysicalPathMiscInfoTitle,
        handleExpandMiscInfos,
      ),
      title: 'Misc Info',
      width: VIEW_DATA_TABLE_COLUMN_MISC_INFO_WIDTH,
    },
  );

  return loadReviewColumns;
};
