import { PROFILE_CHANGE_ROW_EDIT_KEY_LABEL } from 'components/organisms/ProfileInformationView/constants';
import {
  EDIT_PHYSICAL_SEGMENTS_PROFILES_LABEL,
  INITIAL_RECORD_ID,
  PROFILE_PATH_RAMP_START_KEY,
  PROFILE_PATH_RAMP_STOP_KEY,
  PROFILE_PATH_START_KEY,
  PROFILE_PATH_STOP_KEY,
} from 'constants/Detail';
import { ID_KEY } from 'constants/General';
import { EProfileDataGridCellType } from 'enums/Detail';
import { ESeverity } from 'enums/General';
import { IValidationMessage } from 'interfaces/Component';
import { IProfileDataGridCell } from 'interfaces/Detail';
import {
  IETagExceptionTransAllocProfileChange,
  IETagPathEnergyProfile,
  IETagPathTransmissionAllocationProfile,
  IETagPhysicalSegmentsProfile,
  IETagProfileChangeRequest,
  IETagTransmissionAllocation,
  IETagTransmissionAllocationProfileDetail,
  IETagTransmissionPhysicalSegment,
} from 'interfaces/ETag';
import {
  detailSetSelectedRequestType,
  detailEditETagDetail,
} from 'reduxes/Detail/actions';
import { IDetailState } from 'reduxes/Detail/types';
import { TDetailValidations, TProfileDataGridRow } from 'types/Detail';
import { TStateTransform } from 'types/General';
import {
  getAdjustedContractNumber,
  getEditInfoKey,
  getGenerationKey,
  getLoadKey,
  getSplitEditInfoKey,
  getTransmissionName,
  stopDisabledDate,
} from 'utils/detail';
import {
  getKeyForTransAllocProfile,
  getKeyForTransmissionEnergy,
} from 'utils/detail-energy';
import { isEmptyValue } from 'utils/general';
import { ZonedDateTime } from 'utils/zonedDateTime';

export const checkRowId = (row: TProfileDataGridRow) => {
  if (
    row.id === undefined ||
    row.id === null ||
    row.id.type !== EProfileDataGridCellType.String
  ) {
    throw new Error(`Invalid row id for row: ${row}`);
  }
};

export const getInitialRowForId = (
  id: string,
  initialProfileInformationDataSet?: TProfileDataGridRow[],
): TProfileDataGridRow | undefined => {
  if (initialProfileInformationDataSet !== undefined) {
    return initialProfileInformationDataSet.find(
      (initialRow: TProfileDataGridRow): boolean => {
        const initialRowId: IProfileDataGridCell | null | undefined =
          initialRow[ID_KEY];

        if (initialRowId === undefined) {
          throw new Error(
            `Missing key: id for initialRow: ${JSON.stringify(initialRow)}`,
          );
        }

        return initialRowId !== null && id === initialRowId.value;
      },
    );
  }

  return undefined;
};

export const stopDisabledDateForRow =
  (row: TProfileDataGridRow) =>
  (dateTime: ZonedDateTime | null, isSelected?: boolean): boolean => {
    if (dateTime !== null) {
      const startCell: IProfileDataGridCell | null | undefined =
        row[PROFILE_PATH_START_KEY];

      if (startCell === undefined) {
        throw new Error(
          `Missing key: ${PROFILE_PATH_START_KEY} for row: ${JSON.stringify(
            row,
          )}`,
        );
      }

      if (startCell !== null) {
        if (startCell.type !== EProfileDataGridCellType.DateTimeString) {
          throw new Error('Invalid start cell type');
        }

        if (startCell !== null) {
          if (startCell.type !== EProfileDataGridCellType.DateTimeString) {
            throw new Error('Invalid start cell type');
          }

          return stopDisabledDate(
            startCell.value as string,
            dateTime,
            isSelected,
          );
        }
      }
    }

    return false;
  };

export const isInitialProfileDataGridRow = (
  row: TProfileDataGridRow,
): boolean => {
  const rowId: IProfileDataGridCell | null | undefined = row[ID_KEY];

  if (rowId === undefined) {
    throw new Error(`Missing key: id for row: ${JSON.stringify(row)}`);
  }

  if (rowId === null || rowId.type !== EProfileDataGridCellType.String) {
    throw new Error(`Invalid rowId for row: ${JSON.stringify(row)}`);
  }

  const { primaryId } = getSplitEditInfoKey(rowId.value as string);

  return primaryId === INITIAL_RECORD_ID;
};

export const isInitialProfileDataGrid = (
  rows: TProfileDataGridRow[],
): boolean =>
  rows.length === 1 ? isInitialProfileDataGridRow(rows[0]) : false;

export const validateProfileInformation = (
  physicalSegmentsProfiles: IETagPhysicalSegmentsProfile[] | null,
) => {
  const detailValidations: TDetailValidations = {};
  const validationMessages: IValidationMessage[] = [];

  if (physicalSegmentsProfiles !== null) {
    let isPhysicalSegmentsProfilesValid: boolean = true;

    physicalSegmentsProfiles.forEach(
      (
        eTagPhysicalSegmentsProfile: IETagPhysicalSegmentsProfile,
        index: number,
      ) => {
        if (isEmptyValue(eTagPhysicalSegmentsProfile.start)) {
          isPhysicalSegmentsProfilesValid = false;

          validationMessages.push({
            message: `Missing Start for row ${index + 1}`,
            severity: ESeverity.Error,
          });
        }

        if (isEmptyValue(eTagPhysicalSegmentsProfile.stop)) {
          isPhysicalSegmentsProfilesValid = false;

          validationMessages.push({
            message: `Missing Stop for row ${index + 1}`,
            severity: ESeverity.Error,
          });
        }
      },
    );

    detailValidations[
      getEditInfoKey(EDIT_PHYSICAL_SEGMENTS_PROFILES_LABEL, 0, 0)
    ] = isPhysicalSegmentsProfilesValid;
  }

  return {
    detailValidations,
    validationMessages,
  };
};

const unpackTagProfileChanges = (
  profileChangeRequest: IETagProfileChangeRequest,
): [
  IETagPathEnergyProfile[],
  IETagPathTransmissionAllocationProfile[],
  IETagExceptionTransAllocProfileChange[],
] => {
  // Unpack the energy profile, trans alloc base changes, and exception_trans_alloc_profile_change from the request
  let profileChangeEnergyProfiles: IETagPathEnergyProfile[] = [];
  profileChangeRequest.tag_profile_changes?.energy_profile_changes?.forEach(
    (energyProfileChange) => {
      profileChangeEnergyProfiles.push({
        energy_profile_details: energyProfileChange.energy_profile_details,
        profile_id: energyProfileChange.profile_ref,
      });
    },
  );

  let profileChangeBaseTransAllocs: IETagPathTransmissionAllocationProfile[] =
    [];
  profileChangeRequest.tag_profile_changes?.base_trans_alloc_profile_changes?.forEach(
    (baseTransAllocChange) => {
      profileChangeBaseTransAllocs.push({
        trans_alloc_profile_attributes:
          baseTransAllocChange.trans_alloc_profile_attributes,
        trans_alloc_profile_details:
          baseTransAllocChange.trans_alloc_profile_details,
      });
    },
  );

  let profileChangeExceptionTransAllocs: IETagExceptionTransAllocProfileChange[] =
    [];
  profileChangeRequest.tag_profile_changes?.exception_trans_alloc_profile_changes?.forEach(
    (exceptionTransAllocChange) => {
      profileChangeExceptionTransAllocs.push({
        changed_trans_alloc_profile_details:
          exceptionTransAllocChange.changed_trans_alloc_profile_details,
        trans_alloc_id: exceptionTransAllocChange.trans_alloc_id,
      });
    },
  );
  return [
    profileChangeEnergyProfiles,
    profileChangeBaseTransAllocs,
    profileChangeExceptionTransAllocs,
  ];
};

const energyProfilesToDataGridRows = (
  profileChangeEnergyProfiles: IETagPathEnergyProfile[],
  detailState: IDetailState,
  rowsByRowKey: Record<string, TProfileDataGridRow>,
) => {
  // Loop through all the energy profiles and turn them into TProfileDataGridRow objects; on non draft, non template tags (which should be every
  // tag we are doing this on), there should be no way to uncheck the "Same Energy Profile" button and enable unique energy profiles,
  // but we'll check here anyway just to be safe

  //iterating for a column
  for (let energyProfile of profileChangeEnergyProfiles) {
    // On the detail screen (non draft and non template) the only profile we can edit with a profile change is the generation profile, so we
    // only need to look for and apply those changes
    // if (energyProfile.profile_id === 1) {
    // This is the generation segment/cell, so apply all the details as such
    let currentRowNum = 0;

    for (let energyProfileDetail of energyProfile.energy_profile_details ??
      []) {
      if (!energyProfileDetail.energy_profile_type) {
        throw new Error('Error loading EnergyProfile No energy Profile Type');
      }
      const profileChangeRow =
        rowsByRowKey[
          getEditInfoKey(PROFILE_CHANGE_ROW_EDIT_KEY_LABEL, currentRowNum, 0)
        ];
      currentRowNum += 1;
      // Get the generation source from the detail state so we can build the correct cell key

      let segmentKey: string | null = null;

      if (
        energyProfile.profile_id ===
        detailState.generationPhysicalSegment?.profile_ref
      ) {
        segmentKey = getGenerationKey(
          detailState.generationPhysicalSegment?.generation_source
            ?.point_name ?? '-',
          energyProfileDetail.energy_profile_type,
        );
      } else if (
        energyProfile.profile_id ===
        detailState.loadPhysicalSegment?.profile_ref
      ) {
        segmentKey = getLoadKey(
          detailState.loadPhysicalSegment?.load_sink?.point_name ?? '-',
          energyProfileDetail.energy_profile_type,
        );
      } else {
        const tps = detailState.transmission_physical_segments?.find(
          (transPhysiscalSegment) =>
            transPhysiscalSegment.pod_profile_ref === energyProfile.profile_id,
        );
        if (tps) {
          segmentKey = getKeyForTransmissionEnergy({
            physicalSegmentRef: tps?.physical_segment_id,
            porPodLossOrAllocTotals: 'POD',
          });
        }
      }

      if (segmentKey) {
        profileChangeRow[segmentKey] = {
          type: EProfileDataGridCellType.Number,
          value: energyProfileDetail.mw ?? 0,
        };
      }

      // Build out the transmission segment cells with null values (may fill them in with the profileChangeExceptionTransAllocs below)
      for (let transAlloc of detailState.transmissionAllocations ?? []) {
        const key: string = getKeyForTransAllocProfile({
          adjustedContractNumber: getAdjustedContractNumber(transAlloc),
          physicalSegmentRef: transAlloc.physical_segment_ref,
          transAllocId: transAlloc.trans_alloc_id,
          transmissionName: getTransmissionName(
            transAlloc,
            detailState.transmission_physical_segments,
          ),
        });
        profileChangeRow[key] = null;
      }
    }
    // }
  }
  // return ;
};

const transAllocsToDataGridRows = (
  profileChangeBaseTransAllocs: IETagPathTransmissionAllocationProfile[],
  profileChangeExceptionTransAllocs: IETagExceptionTransAllocProfileChange[],
  detailState: IDetailState,
  rowsByRowKey: Record<string, TProfileDataGridRow>,
) => {
  profileChangeBaseTransAllocs.forEach(
    (profileChangeBaseTransAlloc: IETagPathTransmissionAllocationProfile) => {
      const matchedTransAlloc = detailState.transmissionAllocations?.find(
        (transmissionAllocation) =>
          transmissionAllocation.trans_alloc_id ===
          profileChangeBaseTransAlloc.trans_alloc_profile_attributes
            ?.trans_alloc_id,
      );

      if (matchedTransAlloc) {
        const transAllocCellKey: string = getKeyForTransAllocProfile({
          adjustedContractNumber: getAdjustedContractNumber(matchedTransAlloc),
          physicalSegmentRef: matchedTransAlloc.physical_segment_ref,
          transAllocId: matchedTransAlloc.trans_alloc_id,
          transmissionName: getTransmissionName(
            matchedTransAlloc,
            detailState.transmission_physical_segments,
          ),
        });

        if (profileChangeBaseTransAlloc.trans_alloc_profile_details) {
          profileChangeBaseTransAlloc.trans_alloc_profile_details.forEach(
            (
              transAllocProfileDetail: IETagTransmissionAllocationProfileDetail,
              index: number,
            ) => {
              rowsByRowKey[
                getEditInfoKey(PROFILE_CHANGE_ROW_EDIT_KEY_LABEL, index, 0)
              ][transAllocCellKey] = {
                type: EProfileDataGridCellType.Number,
                value: transAllocProfileDetail.mw ?? '',
              };
            },
          );
        }
      }
    },
  );

  profileChangeExceptionTransAllocs.forEach(
    (
      profileChangeExceptionTransAlloc: IETagExceptionTransAllocProfileChange,
    ) => {
      const matchedTransAlloc = detailState.transmissionAllocations?.find(
        (transmissionAllocation) =>
          transmissionAllocation.trans_alloc_id ===
          profileChangeExceptionTransAlloc.trans_alloc_id,
      );

      if (matchedTransAlloc) {
        const transAllocCellKey: string = getKeyForTransAllocProfile({
          adjustedContractNumber: getAdjustedContractNumber(matchedTransAlloc),
          physicalSegmentRef: matchedTransAlloc.physical_segment_ref,
          transAllocId: matchedTransAlloc.trans_alloc_id,
          transmissionName: getTransmissionName(
            matchedTransAlloc,
            detailState.transmission_physical_segments,
          ),
        });

        if (
          profileChangeExceptionTransAlloc.changed_trans_alloc_profile_details
        ) {
          profileChangeExceptionTransAlloc.changed_trans_alloc_profile_details.forEach(
            (
              transAllocProfileDetail: IETagTransmissionAllocationProfileDetail,
              index: number,
            ) => {
              rowsByRowKey[
                getEditInfoKey(PROFILE_CHANGE_ROW_EDIT_KEY_LABEL, index, 0)
              ][transAllocCellKey] = {
                type: EProfileDataGridCellType.Number,
                value: transAllocProfileDetail.mw ?? '',
              };
            },
          );
        }
      }
    },
  );
};

// Main goal with this function is to transform the IETagProfileChangeRequest into a list of
// TProfileDataGridRow's to pass into the detail state's profileChanges attribute
export const profileChangeRequestToDetailState = (
  profileChangeRequest: IETagProfileChangeRequest,
): TStateTransform<IDetailState> => {
  return (detailState: IDetailState): IDetailState => {
    if (profileChangeRequest.tag_profile_changes === undefined) {
      throw new Error(
        'Error loading existing profile change, no tag_profile_changes found',
      );
    }

    if (!detailState.generationPhysicalSegment?.generation_source?.point_name) {
      throw new Error(
        'Error applying existing profile change: tag must have a generation physical segment',
      );
    }

    const [
      profileChangeEnergyProfiles,
      profileChangeBaseTransAllocs,
      profileChangeExceptionTransAllocs,
    ] = unpackTagProfileChanges(profileChangeRequest);

    // No need to auto fill in the misc infos, notes, or contact info from the existing profile change; they will fill
    // this out themselves when submitting the request from our UI
    // Unsure if we need to verify that the incoming profile change has a tag_id matching the one of the tag we're loading it on,
    // or if we trust the producer of these URLs to only pass in profile changes for the tag that's loading; will skip for now (11/19/24)

    // Also ignoring loss accountings and curtailments for now (11/18/24), focusing only on trans alloc and energy profiles
    const rowsByRowKey: Record<string, TProfileDataGridRow> =
      initializeProfileDataGridRow(
        profileChangeEnergyProfiles,
        detailState.transmission_physical_segments ?? [],
      );

    energyProfilesToDataGridRows(
      profileChangeEnergyProfiles,
      detailState,
      rowsByRowKey,
    );

    const incomingProfileChanges: TProfileDataGridRow[] =
      Object.values(rowsByRowKey).sort();

    transAllocsToDataGridRows(
      profileChangeBaseTransAllocs,
      profileChangeExceptionTransAllocs,
      detailState,
      rowsByRowKey,
    );

    let updatedDetailState: IDetailState = {
      ...detailState,
      profileChanges: incomingProfileChanges,
    };
    return updatedDetailState;
  };
};

export const profileBaseTransAllocChangeToDetailState = (
  baseTransAllocProfileChanges: IETagPathTransmissionAllocationProfile[],
): TStateTransform<IDetailState> => {
  return (detailState: IDetailState): IDetailState => {
    let updatedTransAllocs: IETagTransmissionAllocation[] =
      detailState.transmissionAllocations ?? [];
    for (let baseTransAllocProfileChange of baseTransAllocProfileChanges) {
      let changeApplied: boolean = false;
      const baseTransAllocProfileChangeAttributes: IETagTransmissionAllocation | null =
        baseTransAllocProfileChange.trans_alloc_profile_attributes;
      // Check if this is an existing trans alloc we need to change
      for (let transAlloc of updatedTransAllocs ?? []) {
        if (
          baseTransAllocProfileChangeAttributes?.trans_alloc_id ===
          transAlloc.trans_alloc_id
        ) {
          transAlloc = baseTransAllocProfileChangeAttributes;
          changeApplied = true;
        }
      }
      if (!changeApplied && baseTransAllocProfileChangeAttributes) {
        updatedTransAllocs.push(baseTransAllocProfileChangeAttributes);
      }
    }
    let updatedDetailState: IDetailState = {
      ...detailState,
      transmissionAllocations: updatedTransAllocs,
    };
    return updatedDetailState;
  };
};

function initializeProfileDataGridRow(
  profileChangeEnergyProfiles: IETagPathEnergyProfile[],
  transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[],
): Record<string, TProfileDataGridRow> {
  let currentRowNum: number = 0;
  const rowsByRowKey: Record<string, TProfileDataGridRow> = {};

  for (const energyProfileDetail of profileChangeEnergyProfiles[0]
    .energy_profile_details!) {
    const rowKey = getEditInfoKey(
      PROFILE_CHANGE_ROW_EDIT_KEY_LABEL,
      currentRowNum,
      0,
    );
    let profileChangeRow: TProfileDataGridRow = {};
    profileChangeRow[ID_KEY] = {
      type: EProfileDataGridCellType.String,
      value: rowKey,
    };
    profileChangeRow[PROFILE_PATH_START_KEY] = {
      type: EProfileDataGridCellType.DateTimeString,
      value: energyProfileDetail.start ?? '',
    };
    profileChangeRow[PROFILE_PATH_STOP_KEY] = {
      type: EProfileDataGridCellType.DateTimeString,
      value: energyProfileDetail.stop ?? '',
    };
    profileChangeRow[PROFILE_PATH_RAMP_START_KEY] = {
      type: EProfileDataGridCellType.Number,
      value: energyProfileDetail.start_ramp_dur ?? '',
    };
    profileChangeRow[PROFILE_PATH_RAMP_STOP_KEY] = {
      type: EProfileDataGridCellType.Number,
      value: energyProfileDetail.stop_ramp_dur ?? '',
    };
    transmissionPhysicalSegments.forEach((transmissionPhysicalSegment) => {
      profileChangeRow[
        getKeyForTransmissionEnergy({
          physicalSegmentRef: transmissionPhysicalSegment.physical_segment_id,
          porPodLossOrAllocTotals: 'POD',
        })
      ] = null;
    });
    rowsByRowKey[rowKey] = profileChangeRow;
    currentRowNum += 1;
  }

  return rowsByRowKey;
}

export const applyProfileChangeToDetail = async (
  profileChangeResponse: IETagProfileChangeRequest,
  dispatch: any,
): Promise<void> => {
  // Set the profile change request type first so that we have profile grid data rows to work with
  dispatch(
    detailSetSelectedRequestType({
      selectedRequestType: profileChangeResponse.tag_id.request_type,
    }),
  );

  // Apply any base profile trans alloc changes (not including their details) to the detail's transmissionAllocations
  dispatch(
    detailEditETagDetail({
      isDetailEdited: true,
      stateTransform: profileBaseTransAllocChangeToDetailState(
        profileChangeResponse.tag_profile_changes
          ?.base_trans_alloc_profile_changes ?? [],
      ),
    }),
  );

  dispatch(
    detailEditETagDetail({
      isDetailEdited: true,
      stateTransform: profileChangeRequestToDetailState(profileChangeResponse),
    }),
  );
};
