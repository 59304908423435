import { ETheme } from 'enums/Style';

export const DEFAULT_THEME: ETheme = ETheme.Light;

export const THEMES = {
  [ETheme.Dark]: `${process.env.PUBLIC_URL}/assets/css/compact-dark-theme-${process.env.REACT_APP_CSS_TIMESTAMP}.css`,
  [ETheme.Light]: `${process.env.PUBLIC_URL}/assets/css/compact-light-theme-${process.env.REACT_APP_CSS_TIMESTAMP}.css`,
};

// Colours
export const COMPONENT_BACKGROUND_DARK = '#242424';
export const COMPONENT_BACKGROUND_LIGHT = '#ffffff';

export const TEXT_DARK = 'rgba(255, 255, 255, 0.85)';
export const TEXT_LIGHT = 'rgba(0, 0, 0, 0.65)';

export const TEXT_DISABLED_DARK = 'rgba(255, 255, 255, 0.3)';
export const TEXT_DISABLED_LIGHT = 'rgba(0, 0, 0, 0.25)';

export const BACKGROUND_BLACK = '#1f1f1f';
export const BACKGROUND_WHITE = '#fff';

export const ROW_BACKGROUND_DARK = '#1f1f1f';
export const ROW_BACKGROUND_LIGHT = '#f5f5f5';

export const HOVER_BACKGROUND_DARK = '#393939';
export const HOVER_BACKGROUND_LIGHT = '#f5f5f5';

export const ERROR_RED = '#ff4d4f';
export const HIGHLIGHT_BLUE = '#188fff';
export const HIGHLIGHT_DARK_MAGENTA = '#8b008b';
export const HIGHLIGHT_ORANGE = '#ffa500';
export const HIGHLIGHT_MAGENTA = '#ff00ff';
export const SUCCESS_GREEN = '#52c41a';
export const WARNING_ORANGE = '#faad14';

export const SEPARATOR_DARK = '#303030';
export const SEPARATOR_LIGHT = '#f0f0f0';

export const INPUT_BACKGROUND_DARK = 'transparent';
export const INPUT_BACKGROUND_LIGHT = BACKGROUND_WHITE;

export const INPUT_BACKGROUND_EXPAND_DARK = '#222222';
export const INPUT_BACKGROUND_EXPAND_LIGHT = BACKGROUND_WHITE;
export const BACKGROUND_BLACK_FLOAT = '#323232';

export const STATE_BLUE = '#1e90ff';
export const STATE_GREEN = '#228b22';
export const STATE_GREY = '#8a8a8a';
export const STATE_ORANGE = '#d48c1f';
export const STATE_RED = '#c33333';

export const DETAIL_VIEW_HEAD_COLOUR_DARK = '#27273f';
export const DETAIL_VIEW_HEAD_COLOUR_LIGHT = '#efeff5';

export const CELL_BORDER_COLOUR_DARK = '#1a80de';
export const CELL_BORDER_COLOUR_LIGHT = '#40a9ff';

export const CELL_BACKGROUND_COLOUR_DARK = 'rgb(26 128 222 / 20%)';
export const CELL_BACKGROUND_COLOUR_LIGHT = 'rgb(24 144 255 / 20%)';

// Values
export const ERROR_NOTIFICATION_CONTENT_MAX_WIDTH = '280px';
export const ERROR_NOTIFICATION_MESSAGE_MAX_HEIGHT = '400px';

export const STANDARD_SPACING_VALUE = 8;
export const STANDARD_SPACING = `${STANDARD_SPACING_VALUE}px`;
export const SMALL_SPACING_VALUE = 3;

// Navigation
export const NAVIGATION_ACTIONS_HEIGHT_VALUE = 24;
export const NAVIGATION_ACTIONS_HEIGHT = `${NAVIGATION_ACTIONS_HEIGHT_VALUE}px`;
export const NAVIGATION_ACTIONS_TOP_VALUE = 13;
export const NAVIGATION_ACTIONS_TOP = `${NAVIGATION_ACTIONS_TOP_VALUE}px`;
export const NAVIGATION_ACTIONS_MARGIN_RIGHT_VALUE = 12;
export const NAVIGATION_ACTIONS_MARGIN_RIGHT = `${NAVIGATION_ACTIONS_MARGIN_RIGHT_VALUE}px`;
export const NAVIGATION_ICON_FONT_VALUE = 18;
export const NAVIGATION_ICON_FONT = `${NAVIGATION_ICON_FONT_VALUE}px`;
export const NAVIGATION_ICON_WIDTH_VALUE = 20;
export const NAVIGATION_ICON_WIDTH = `${NAVIGATION_ICON_WIDTH_VALUE}px`;
export const NAVIGATION_ICON_SPACING = STANDARD_SPACING_VALUE;
export const NAVIGATION_ICON_SPACING_VALUE = `${STANDARD_SPACING_VALUE}px`;

export const BORDER_SIZE_VALUE = 1;

export const BORDER_RADIUS_BASE = '3px';

export const VIEW_PADDING_VALUE = 2;

export const CELL_PADDING_VALUE = 2;
export const CELL_CONTENT_HEIGHT_VALUE = 20;
export const CELL_CONTENT_HEIGHT = `${CELL_CONTENT_HEIGHT_VALUE}px`;
export const CELL_CONTENT_PADDING = `0 ${CELL_PADDING_VALUE}px`;

export const HEADER_CELL_MIN_HEIGHT_VALUE = 58 + CELL_PADDING_VALUE * 2;

export const ETAG_DATA_TABLE_ROW_HEIGHT: number =
  CELL_CONTENT_HEIGHT_VALUE + BORDER_SIZE_VALUE;

export const INPUT_HEIGHT_VALUE = 28;
export const INPUT_HEIGHT = `${INPUT_HEIGHT_VALUE}px`;
export const EXPANDED_INPUT_HEIGHT_VALUE = 40;
export const ICON_BUTTON_SIZE_VALUE = INPUT_HEIGHT_VALUE;
export const FILTER_BUTTON_WIDTH_VALUE = 20;
export const FILTER_LAYOUT_RIGHT_MARGIN_VALUE = 2;
export const FILTER_LAYOUT_BOTTOM_MARGIN_VALUE = 4;

export const EXPANSION_TRANSITION_IN_SECONDS_VALUE = 0.35;

export const TABLE_CONFIGURATION_SELECT_WIDTH_VALUE = 144;
export const CUSTOM_FILTER_SELECT_WIDTH_VALUE = 144;

export const FILTER_CONFIGURATION_SELECT_WIDTH_VALUE = 144;

export const ENUMERATION_DROPDOWN_MAX_HEIGHT_VALUE = 352;

export const SUMMARY_ROW_HEIGHT_VALUE = 33;

export const TO_ENTITY_TITLE_HEIGHT_VALUE = INPUT_HEIGHT_VALUE;
export const ACTION_BAR_HEIGHT_VALUE = INPUT_HEIGHT_VALUE;
export const ACTION_BAR_MARGIN_RIGHT_VALUE = 6;
export const LAYOUT_PADDING_VALUE = 4;

export const ETAG_MANAGER_ACTION_BAR_VERTICAL_PADDING_VALUE =
  0.75 * STANDARD_SPACING_VALUE;

export const VIEW_DATA_TABLE_COLUMN_TEXT_WIDTH = '66px';
export const VIEW_DATA_TABLE_COLUMN_ID_COLUMN_WIDTH = '32px';
export const VIEW_DATA_TABLE_COLUMN_CONTRACTS_COLUMN_WIDTH = '65px';
export const VIEW_DATA_TABLE_COLUMN_CONTACT_INFO_COLUMN_WIDTH = '55px';
export const VIEW_DATA_TABLE_COLUMN_MISC_INFO_WIDTH_VALUE = 62;
export const VIEW_DATA_TABLE_COLUMN_MISC_INFO_WIDTH = `${VIEW_DATA_TABLE_COLUMN_MISC_INFO_WIDTH_VALUE}px`;
export const VIEW_DATA_TABLE_COLUMN_LOSS_PERCENTAGES_WIDTH = '62px';
export const VIEW_DATA_TABLE_COLUMN_ENTITY_COLUMN_WIDTH = '73px';
export const VIEW_DATA_TABLE_COLUMN_SE_COLUMN_WIDTH = '74px';
export const VIEW_DATA_TABLE_COLUMN_OASIS_INFO_COLUMN_WIDTH = '91px';
export const VIEW_DATA_TABLE_COLUMN_LOSS_METHODS_COLUMN_WIDTH = '105px';
export const VIEW_DATA_TABLE_COLUMN_POINT_COLUMN_WIDTH = '68px';
export const VIEW_DATA_TABLE_COLUMN_LOSS_METHOD_WIDTH = '91px';
export const VIEW_DATA_TABLE_COLUMN_LOSS_TAG_IDS_WIDTH = '79px';
export const VIEW_DATA_TABLE_COLUMN_LOSS_CONTRACT_NUMBERS_WIDTH_VALUE = 142;
export const VIEW_DATA_TABLE_COLUMN_LOSS_CONTRACT_NUMBERS_WIDTH = `${VIEW_DATA_TABLE_COLUMN_LOSS_CONTRACT_NUMBERS_WIDTH_VALUE}px`;

export const VIEW_DATA_TABLE_COLUMN_CONTRACT_INPUT_WIDTH = '200px';
export const VIEW_DATA_TABLE_COLUMN_PID_SELECT_COLUMN_WIDTH = '57px';
export const VIEW_DATA_TABLE_COLUMN_PSE_SELECT_COLUMN_WIDTH = '103px';
export const VIEW_DATA_TABLE_COLUMN_PRODUCT_SELECT_COLUMN_WIDTH = '75px';
export const VIEW_DATA_TABLE_COLUMN_MID_SELECT_COLUMN_WIDTH = '52px';
export const VIEW_DATA_TABLE_COLUMN_ENTITY_SELECT_COLUMN_WIDTH = '81px';
export const VIEW_DATA_TABLE_COLUMN_SE_SELECT_COLUMN_WIDTH = '72px';
export const VIEW_DATA_TABLE_COLUMN_POINT_SELECT_COLUMN_WIDTH = '90px';
export const VIEW_DATA_TABLE_COLUMN_OASIS_INFO_EDIT_COLUMN_WIDTH = '240px';
export const VIEW_DATA_TABLE_COLUMN_LOSS_METHODS_EDIT_COLUMN_WIDTH = '105px';
export const VIEW_DATA_TABLE_COLUMN_DATE_TIME_PICKER_WIDTH = '146px';
export const VIEW_DATA_TABLE_COLUMN_OASIS_INPUT_COLUMN_WIDTH = '277px';
export const VIEW_DATA_TABLE_COLUMN_NITS_RESOURCE_INPUT_COLUMN_WIDTH = '150px';

export const VIEW_DATA_TABLE_CELL_PADDING = '4px';

export const LOSS_TAG_IDS_WIDTH = '199px';

export const DATA_TABLE_EMPTY_ROW_HEIGHT = '156px';

export const DATA_GRID_DATE_TIME_COLUMN_WIDTH_VALUE = 139;
export const DATA_GRID_DATA_COLUMN_WIDTH_VALUE = 80;

export const DETAIL_POPOVER_DATA_TABLE_MAXIMUM_HEIGHT = '37vh';
export const DETAIL_REVIEW_FLOAT_OVER_MAXIMUM_HEIGHT = '53vh';

export const SCROLLBAR_WIDTH_VALUE = 10;
export const SCROLLBAR_WIDTH = `${SCROLLBAR_WIDTH_VALUE}px`;

export const LEGEND_CELL_CONTENT_HEIGHT = `${CELL_CONTENT_HEIGHT_VALUE}px`;
export const LEGEND_CELL_CONTENT_PADDING = '4px';

export const BUTTON_ICON_DIMENSION_VALUE = ICON_BUTTON_SIZE_VALUE / 2;
export const BUTTON_ICON_DIMENSION = `${BUTTON_ICON_DIMENSION_VALUE}px`;
export const ICON_BUTTON_SIZE = `${ICON_BUTTON_SIZE_VALUE}px`;

export const TO_ENTITY_TAG_WIDTH_VALUE = 111;

export const PHYSICAL_SEGMENTS_PROFILES_HEADER_ROW_HEIGHT_VALUE = 53;
export const PROFILE_DATA_GRID_ROW_HEIGHT_VALUE = 35;

export const MARKET_INFO_PRICES_DATA_GRID_HEADER_ROW_HEIGHT_VALUE = 41;
export const MARKET_INFO_PRICES_DATA_GRID_ROW_HEIGHT_VALUE = 35;
export const MARKET_INFO_PRICES_DATA_GRID_DATA_COLUMN_WIDTH_VALUE = 100;

export const PRINT_WIDTH_A4_PORTRAIT_VALUE = 1080;
export const PRINT_WIDTH_A4_PORTRAIT = `${PRINT_WIDTH_A4_PORTRAIT_VALUE}px`;
export const PRINT_WIDTH_TUNER_MAXIMUM_LEFT_OFFSET_VALUE = -151;

export const ICON_FONT_SIZE = '20px';

// Shadows
export const BASE_BOX_SHADOW =
  '0 3px 6px -4px rgba(0, 0, 0, 0.24), 0 6px 16px 0 rgba(0, 0, 0, 0.16), 0 9px 28px 8px rgba(0, 0, 0, 0.1)';
export const FLOAT_BOX_SHADOW = BASE_BOX_SHADOW;

// Borders
export const LEGEND_CELL_BORDER = '2px solid #d0d0d0';

// z-indexes
export const MAXIMUM_Z_INDEX = Number.MAX_SAFE_INTEGER;

export const COLUMN_STICKY_LEFT_OFFSET_Z_INDEX = 200;
export const COLUMN_STICKY_RIGHT_OFFSET_Z_INDEX = 400;

export const INPUT_EXPAND_OVER_Z_INDEX = 500;
export const BADGE_Z_INDEX = 900;
export const LEGEND_FLOAT_OVER_Z_INDEX = 1000;

export const VIEW_BASE_Z_INDEX = 100;
export const VIEW_EXPAND_Z_INDEX = 200;
export const VIEW_EXPAND_BUTTON_Z_INDEX = 300;

export const VIEW_TABLE_EXPAND_Z_INDEX = 300;

export const VIEW_EDIT_BUTTON_Z_INDEX = 200;

export const DATA_GRID_HEADER_ROW_Z_INDEX = 200;
export const MARCHING_ANTS_Z_INDEX = 100;

export const PRINT_WIDTH_TUNER_INITIAL_POSITION_INDICATOR_Z_INDEX = 1000;

export const PUSH_RIGHT_VALUE = `${
  (NAVIGATION_ICON_WIDTH_VALUE + NAVIGATION_ICON_SPACING) * 4
}px`;

// css properties
export const BUTTON_ICON_DIMENSIONS = `
  height: ${BUTTON_ICON_DIMENSION_VALUE}px;
  width: ${BUTTON_ICON_DIMENSION_VALUE}px;
`;

export const COLUMN_LAYOUT_SHARED_STYLES = `
  display: flex;
  flex-direction: column;

  > :not(:last-child) {
    margin-bottom: ${STANDARD_SPACING};
  }
`;

export const ROW_LAYOUT_SHARED_STYLES = `
  display: flex;
  flex-direction: row;

  > :not(:last-child) {
    margin-right: ${STANDARD_SPACING};
  }
`;

export const LIST_ROW_SHARED_STYLES = `
  line-height: 22px;
  min-height: 28px;
  overflow: hidden;
  padding: 3px ${STANDARD_SPACING};
  text-overflow: ellipsis;
  transition: all 0.3s;
  white-space: nowrap;
`;

export const PAGE_LAYOUT_STYLES = `
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0 ${STANDARD_SPACING};
`;

export const VIEW_DATA_TABLE_SHARED_STYLES = `
  &&& .ant-table {
    &-thead {
      > tr > th {
        padding: 3px 4px;
      }
    }

    &-tbody {
      > tr {
        > td {
          padding: ${VIEW_DATA_TABLE_CELL_PADDING};
        }

        :hover {
          > td {
            background-color: inherit;
          }
        }
      }
    }
  }
`;

export const VIEW_DATA_TABLE_CENTERED_CONTENT = `
  .ant-table {
    &-thead {
      > tr > th {
        text-align: center;
      }
    }

    &-tbody,
    &-summary
    {
      > tr > td {
        text-align: center;
      }
    }
  }
`;

export const VIEW_DATA_TABLE_SCROLL_ADJUST = `
  .ant-table {
    &-content {
      > table {
        padding-right: 20px;
      }
    }
  }
`;

export const DETAIL_HEADER = `
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 15px;
  font-weight: 500;
  white-space: pre;
`;

export const ERROR_BORDER = `
  border: solid 2px ${ERROR_RED};
`;

export const DATA_GRID_CELL_CONTAINER = `
  height: 100%;
  padding: 0 8px;
  width: 100%;
`;

export const CELL_BORDER_STYLE = `
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
`;

export const WARNING_BORDER = `
  border: solid 2px ${WARNING_ORANGE};
`;

export const LOADING_OVERLAY = `
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  z-index: 2;
`;

export const LOADING_BOX = `
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;
