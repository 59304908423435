import Input from 'components/atoms/Input/Input';
import DateTimePicker from 'components/molecules/DateTimePicker/DateTimePicker';
import ProfileDataGridCellReview from 'components/organisms/ProfileDataGridCellReview/ProfileDataGridCellReview';
import {
  PROFILE_PATH_START_KEY,
  PROFILE_PATH_STOP_KEY,
} from 'constants/Detail';
import {
  ARROW_DOWN_KEY,
  ARROW_UP_KEY,
  DIGIT_REGEX,
  ENTER_KEY,
} from 'constants/General';
import { DATE_TIME_FORMAT, TIME_FORMAT } from 'constants/time';
import { EProfileDataGridCellType, EProfileFormat } from 'enums/Detail';
import { EViewMode } from 'enums/View';
import { IDataGridSelectionContext } from 'interfaces/Component';
import { IProfileDataGridCell } from 'interfaces/Detail';
import {
  ChangeEvent,
  Context,
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { EditorProps } from 'react-data-grid';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TProfileDataGridRow, TProfileDataGridSummaryRow } from 'types/Detail';
import { TDateTimeInputValidator, TDisabledDateHandler } from 'types/General';
import { TRootState } from 'types/Redux';
import { getProfileInformationRowCellData } from 'utils/detail';
import { isControlShortcut, isEmptyValue } from 'utils/general';
import { autoFocusAndSelectInput } from 'utils/input';
import { toFormattedUtcString } from 'utils/time';
import { getDetailToEntityUserSelectedTimeZone } from 'utils/user';
import { ZonedDateTime } from 'utils/zonedDateTime';

const Container = styled.div`
  height: 100%;
`;

const StyledDateTimePicker = styled(DateTimePicker)`
  height: 100%;

  .ant-picker {
    height: 100%;
  }

  .ant-picker-focused {
    border: unset;
  }
`;

const StyledInput = styled(Input)`
  height: 100%;
  text-align: right;
`;

interface IProfileDataGridCellEditProps
  extends EditorProps<TProfileDataGridRow, TProfileDataGridSummaryRow> {
  alwaysAllowEdit?: boolean;
  DataGridSelectionContext: Context<IDataGridSelectionContext>;
  dateTimeInputValidator?: TDateTimeInputValidator;
  disabledDate?: TDisabledDateHandler;
  initialDataSet?: TProfileDataGridRow[];
  isDisabled?: boolean;
  isUnconstrained?: boolean;
}

const retrievePhysicalSegmentsProfilesRowCellState = (state: TRootState) => {
  const { selectedProfileFormat, selectedRequestKey, viewMode } =
    state.detail.present;
  const timeZone: TTimeZone = getDetailToEntityUserSelectedTimeZone(state);

  return {
    selectedProfileFormat,
    selectedRequestKey,
    timeZone,
    viewMode,
  };
};

const ProfileDataGridCellEdit = ({
  alwaysAllowEdit,
  column,
  DataGridSelectionContext,
  dateTimeInputValidator,
  disabledDate,
  initialDataSet,
  isDisabled,
  isUnconstrained = false,
  onRowChange,
  row,
  rowIdx,
}: IProfileDataGridCellEditProps): JSX.Element => {
  const { onExitEditCallback } = useContext<IDataGridSelectionContext>(
    DataGridSelectionContext,
  );
  const { selectedProfileFormat, selectedRequestKey, timeZone, viewMode } =
    useSelector(retrievePhysicalSegmentsProfilesRowCellState);
  const { key } = column;

  const allowEdit: boolean =
    alwaysAllowEdit ||
    (selectedProfileFormat === EProfileFormat.StartStop &&
      (viewMode === EViewMode.EditETagDraft ||
        viewMode === EViewMode.EditETagTemplate ||
        viewMode === EViewMode.EditETagAdjustment ||
        viewMode === EViewMode.EditETagAdjustmentWithATF));
  const pathStart: IProfileDataGridCell | null | undefined =
    row[PROFILE_PATH_START_KEY];

  if (pathStart === undefined) {
    throw new Error(
      `Missing key: ${PROFILE_PATH_START_KEY} for row: ${JSON.stringify(row)}`,
    );
  }

  const pathStop: IProfileDataGridCell | null | undefined =
    row[PROFILE_PATH_STOP_KEY];

  if (pathStop === undefined) {
    throw new Error(
      `Missing key: ${PROFILE_PATH_STOP_KEY} for row: ${JSON.stringify(row)}`,
    );
  }

  const start: string = isEmptyValue(pathStart)
    ? ''
    : (pathStart!.value as string);
  const stop: string = isEmptyValue(pathStop)
    ? ''
    : (pathStop!.value as string);
  const [dateTimePickerOpen, setDateTimePickerOpen] = useState<
    boolean | undefined
  >(true);
  const [isDateTimePickerInputSetup, setIsDateTimePickerInputSetup] =
    useState<boolean>(false);
  const divRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const dateTimePickerInputRef = useRef<HTMLInputElement | null>(null);
  const rowChangeRef = useRef<TProfileDataGridRow | undefined>(undefined);

  useEffect(() => {
    if (divRef.current) {
      dateTimePickerInputRef.current = divRef.current.querySelector(
        '.ant-picker-input input',
      );

      setIsDateTimePickerInputSetup(true);
    }
  }, [divRef]);

  useEffect(() => {
    if (isDateTimePickerInputSetup && dateTimePickerInputRef.current) {
      dateTimePickerInputRef.current.focus();
      dateTimePickerInputRef.current.select();
    }
  }, [isDateTimePickerInputSetup]);

  const { cellType, cellValue } = useMemo(() => {
    const cell: IProfileDataGridCell | null | undefined = row[key];

    if (cell === undefined) {
      throw new Error(`Invalid key: ${key} for row: ${JSON.stringify(row)}`);
    }

    return getProfileInformationRowCellData(
      cell,
      viewMode,
      selectedRequestKey,
      start,
      stop,
      isUnconstrained,
    );
  }, [isUnconstrained, key, row, selectedRequestKey, start, stop, viewMode]);

  const exitEditMode = useCallback(
    (key?: string) => {
      if (divRef.current) {
        // Fake a user 'Enter' key press to immediately exit editing mode when no
        // editing is allowed
        divRef.current.dispatchEvent(
          new KeyboardEvent('keydown', {
            bubbles: true,
            cancelable: true,
            code: ENTER_KEY,
            key: ENTER_KEY,
          }),
        );

        if (onExitEditCallback !== undefined) {
          onExitEditCallback.callback(key);
        }
      }
    },
    [divRef, onExitEditCallback],
  );

  useEffect(() => {
    if (!allowEdit) {
      exitEditMode();
    }
  }, [allowEdit, exitEditMode]);

  const handleDateTimePickerChange = useCallback(
    (dateTime: ZonedDateTime | null) => {
      rowChangeRef.current = {
        ...row,
        [key]: {
          type: EProfileDataGridCellType.DateTimeString,
          value: dateTime === null ? '' : toFormattedUtcString(dateTime),
        },
      };
    },
    [key, row],
  );

  const handleDateTimePickerOk = useCallback(() => {
    if (rowChangeRef.current !== undefined) {
      onRowChange(rowChangeRef.current, true);

      rowChangeRef.current = undefined;
    }

    setDateTimePickerOpen(undefined);
  }, [onRowChange]);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      let updatedCell: IProfileDataGridCell | null = null;

      if (
        !isEmptyValue(event.target.value) &&
        DIGIT_REGEX.test(event.target.value)
      ) {
        updatedCell = {
          type: EProfileDataGridCellType.Number,
          value: parseInt(event.target.value, 10),
        };
      }

      onRowChange({
        ...row,
        [key]: updatedCell,
      });
    },
    [key, onRowChange, row],
  );

  const handleKeyDown = (keyboardEvent: React.KeyboardEvent) => {
    if (isControlShortcut(keyboardEvent.nativeEvent, 'c')) {
      exitEditMode();
    }

    if (
      allowEdit &&
      dateTimePickerInputRef.current &&
      keyboardEvent.key === ENTER_KEY
    ) {
      handleDateTimePickerOk();
    }

    if (
      keyboardEvent.key === ARROW_DOWN_KEY ||
      keyboardEvent.key === ARROW_UP_KEY
    ) {
      exitEditMode(keyboardEvent.key);
    }
  };

  return (
    <Container onKeyDown={handleKeyDown} ref={divRef}>
      {allowEdit ? (
        <>
          {cellType === EProfileDataGridCellType.DateTimeString ? (
            <StyledDateTimePicker
              allowClear={true}
              dateTimeInputValidator={dateTimeInputValidator}
              disabledDate={disabledDate}
              errorMessagePlacement='bottomLeft'
              format={DATE_TIME_FORMAT}
              isDisabled={isDisabled}
              onChange={handleDateTimePickerChange}
              onOk={handleDateTimePickerOk}
              open={dateTimePickerOpen}
              showTime={{
                format: TIME_FORMAT,
                defaultValue: ZonedDateTime.now(timeZone).startOf('hour'),
              }}
              timeZone={timeZone}
              value={
                (cellValue as string) === ''
                  ? null
                  : ZonedDateTime.parseIso(cellValue as string, timeZone)
              }
            />
          ) : (
            <StyledInput
              inputRef={autoFocusAndSelectInput}
              isDisabled={isDisabled}
              onChange={handleInputChange}
              value={cellValue as string}
            />
          )}
        </>
      ) : (
        <ProfileDataGridCellReview
          column={column}
          DataGridSelectionContext={DataGridSelectionContext}
          initialDataSet={initialDataSet}
          isCellSelected={false}
          onRowChange={onRowChange}
          row={row}
          rowIdx={rowIdx}
        />
      )}
    </Container>
  );
};

export default ProfileDataGridCellEdit;
